import i18n from 'i18n';
import React, { Dispatch, forwardRef, SetStateAction, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Label, SelectField, TextField, useNotification, Switch } from 'scorer-ui-kit';
import { addCamera, updateCamera } from 'services/apiConfig';
import AuthService from 'services/authService';
import styled, { css } from 'styled-components';
import { CAMERA_MANUFACTURER_LIST, CAMERA_WEB_UI_URL, CAMERA_WEB_UI_URL_AXIS, MANUFACTURER_AXIS, MANUFACTURER_HIKVISION } from '../../constants';
import UsePasswordField from '../../hooks/usePassword';
import cloneDeep from 'lodash.clonedeep';

const Container = styled.div`
  margin: -5px 0;
`;

const SwitchText = styled(Label)`
  margin-left: 10px;
  line-height: 22px;
  text-align: left;
  font-size: 14px;
  margin-bottom: 0;
  span{
    margin-bottom: 0;
  }
`;

const InputFieldContainer = styled.div`
  display: flex;
  position: relative;
  top: 51px;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 864px;
`;

const OptionalCameraBox = styled.div`
  display: flex;
  position: relative;
  top: 30px;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 864px;
`;

const InputFieldBox = styled.div`
  min-width: 380px;
`;

const InputBox = styled.div<{ disable?: boolean }>`
  margin: 20px 0;
  ${({ disable }) => disable && css`
    span{
      opacity: 0.5;
    }
  `}
`;

const TextFieldRequired = styled.div`
  span::after{
    font-weight: bold;
    content: " *";
    color: rgb(238, 75, 43);
  }
`;

const TextFieldRequiredLast = styled.div`
  span::after{
    font-weight: bold;
    color: rgb(238, 75, 43);
  }
  span {
    color: rgb(58 58 58);
  }
`;

const BufferTextFieldRequired = styled.div`
  position: relative;
  span::after{
    font-weight: bold;
    content: " *";
    color: rgb(238, 75, 43);
  }
`;

const SecondText = styled.p`
  position: absolute;
  font-size: 14px;
  right: 12px;
  bottom: -5px;
`;

const TextConfigBox = styled.div`
  position: relative;
  margin-top: 87px;
`;

const TextConfig = styled(Label)`
  position: absolute;
  font-size: 16px;
  color: #5a6269;
  background: #f9fafb;
  z-index: 1;
  padding-right: 18px;
`;

const Line = styled.div`
  position: absolute;
  height: 1px;
  top: 10px;
  width: 100%;
  background-color: #eee;
`;

const TextBasicBox = styled.div`
  display: flex;
  white-space: nowrap;
  margin-top: 22px;
  gap:17px;
  max-width: 862px;
  flex-direction: column;
  display: none;
`;

const SwitchBox = styled.div<{ maxWidth: string }>`
  display: flex;
  min-width: ${({ maxWidth }) => maxWidth};
  margin-left: 10px;
`;

const PasswordContainer = styled.div`
  button > div {
    margin-top: 5px;
  }
`;

interface IIPCameraData {
  rtsp_url: string;
  buffer_time: string;
  short_header: boolean;
  username: string;
  password: string;
  camera_manufacture: string;
  camera_model: string;
  camera_web_ui_url: string;
  packet_delay_type: string,
  low_latency_decode: boolean,
}

interface IBasicConfig {
  camera_name: string;
  camera_type: string;
  camera_enabled: boolean;
  notes: string;
}

interface IIPCamera {
  basicConfig: IBasicConfig;
  ipCamera: IIPCameraData;
  streamName: string;
  isEditCamera: boolean;
  setIpCamera: Dispatch<SetStateAction<IIPCameraData>>;
  setEditButtonLoading:(data:boolean)=>void;
  setCompareNewValues:(data:any)=>void;
}

const IPCamera = forwardRef<any, IIPCamera>(({ basicConfig, ipCamera, setIpCamera, streamName, isEditCamera, setEditButtonLoading, setCompareNewValues }, ref) => {

  const { t } = useTranslation(['CommonDict']);
  const { sendNotification } = useNotification();
  const notificationRef = useRef(sendNotification);
  const { push } = useHistory();

  useImperativeHandle(ref, () => ({
    postData: async () => {
      const { camera_manufacture, camera_model, camera_web_ui_url, ...ipData } = ipCamera;
      if (ipData.buffer_time === '') {
        notificationRef.current({
          type: 'error', message: t('Please enter buffer time')
        });
        return;
      }
      if (ipData.rtsp_url.trim() === '') {
        notificationRef.current({
          type: 'error', message: t('Please enter RTSP URL')
        });
        return;
      }
      const r = new RegExp(/^(rtsp|rtspt?):?\/\//);
      if (!(r.test(ipCamera?.rtsp_url))) {
        notificationRef.current({
          type: 'error', message: t('Invalid RTSP URL')
        });
        return;
      }
      if((ipCamera.camera_manufacture === MANUFACTURER_HIKVISION || ipCamera.camera_manufacture === MANUFACTURER_AXIS)){
        const reg = new RegExp(/^(http):\/\/[^ "]+$/);
        if (!(reg.test(ipCamera?.camera_web_ui_url))) {
          notificationRef.current({
            type: 'error', message: t('Invalid camera web UI URL')
          });
          return;
        }
      }else {
        if(ipCamera?.camera_web_ui_url !== ''){
          const reg = new RegExp(/^(https):\/\/[^ "]+$/);
          if (!(reg.test(ipCamera?.camera_web_ui_url))) {
            notificationRef.current({
              type: 'error', message: t('Invalid camera web UI URL')
            });
            return;
          }
        }
      }
      const data = { ...basicConfig, camera_name: basicConfig.camera_name.trim(), notes: basicConfig.notes.trim(), 'video_configuration': { ...ipData, optional_cam_meta: { camera_manufacture, camera_model, camera_web_ui_url } } };
      try {
        if (isEditCamera) {
          setEditButtonLoading(true);
          const { data: { status_code } } = await updateCamera({ ...data, stream_name: streamName });
          if (status_code === 201) {
            notificationRef.current({
              type: 'success', message: t('Camera details updated successfully')
            });
            push('/cameras');
          } else if (status_code === 403) {
            notificationRef.current({ type: 'error', message: t('Authorization required to access') });
            if (await AuthService.logoutUser() !== 200) {
              notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
            }
          }
          else {
            notificationRef.current({ type: 'error', message: t('Failed to add camera') });
          }    
        } else {
          setEditButtonLoading(true);
          const { data: { status_code } } = await addCamera(data);
          if (status_code === 201) {
            notificationRef.current({
              type: 'success', message: t('Camera added successfully')
            });
            setEditButtonLoading(false);
            push('/cameras');
          } else if (status_code === 403) {
            notificationRef.current({ type: 'error', message: t('Authorization required to access') });
            if (await AuthService.logoutUser() !== 200) {
              notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
            }
          }
          else {
            notificationRef.current({ type: 'error', message: t('Failed to add camera') });
          }
        }
        setEditButtonLoading(false);
      } catch (error) {
        console.error((error as Error).message);
        notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
      }
      setEditButtonLoading(false);
    }
  }));

  useEffect(()=>{
    const { camera_manufacture, camera_model, camera_web_ui_url, ...ipData } = ipCamera;
    const data = { ...basicConfig, camera_name: basicConfig.camera_name.trim(), notes: basicConfig.notes.trim(), 'video_configuration': { ...ipData, optional_cam_meta: { camera_manufacture, camera_model, camera_web_ui_url }}};
    setCompareNewValues(data);
  }, [basicConfig, ipCamera, setCompareNewValues]);

  const findIpInURl = useCallback(() => {
    if ((ipCamera.camera_manufacture === MANUFACTURER_HIKVISION && ipCamera.camera_model !== '')) {
      const regex = /\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/;
      const extractIP = ipCamera.rtsp_url.match(regex);
      if (extractIP) {
        setIpCamera((prev) => ({ ...prev, camera_web_ui_url: CAMERA_WEB_UI_URL.replace('{X}', extractIP[0] ) }));
      } else {
        setIpCamera((prev) => ({ ...prev, camera_web_ui_url: '' }));
      }
    }else if (ipCamera.camera_manufacture === MANUFACTURER_AXIS){
      const regex = /\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/;
      const extractIP = ipCamera.rtsp_url.match(regex);
      if (extractIP) {
        setIpCamera((prev) => ({ ...prev, camera_web_ui_url: CAMERA_WEB_UI_URL_AXIS.replace('{X}', extractIP[0] ) }));
      } 
    }
  }, [ipCamera.camera_manufacture, ipCamera.camera_model, ipCamera.rtsp_url, setIpCamera]);

  useEffect(() => {
    findIpInURl();
  }, [findIpInURl]);

  const onChangeSelector = useCallback((event, name) => {
    setIpCamera((prev) => {
      let temp = cloneDeep(prev);
      temp = { ...temp, [name]: event as string };
      return temp;
    });
    if (name === 'camera_manufacture' && event === 'Other') {
      setIpCamera((prev) => {
        let temp = cloneDeep(prev);
        temp = { ...temp, [name]: '', camera_web_ui_url: ''};
        return temp;
      });
    }
    else if (name === 'camera_manufacture' && event === MANUFACTURER_AXIS){
      setIpCamera((prev) => {
        let temp = cloneDeep(prev);
        temp = { ...temp , camera_web_ui_url: '', camera_manufacture:event};
        return temp;
      });
    }
  }, [setIpCamera]);

  const onChangeHandler = useCallback(({ target: { value, name } }) => {
    if (name === 'buffer_time') {
      if ((Number(value) >= 1 && Number(value) <= 9999) || value === '') {
        if (Number(value) === 0) {
          setIpCamera(prev => ({ ...prev, buffer_time: value.substring(0, 1) }));
        } else {
          setIpCamera(prev => ({ ...prev, buffer_time: value.replace(/\D/g, '') }));
        }
      }
    } else {
      if(name === 'rtspURL'){
        setIpCamera((prev) => ({ ...prev, 'username': value, camera_model: t('All') }));
      }else if(name === 'rtspData'){
        setIpCamera((prev) => ({ ...prev, 'password': value, camera_model: t('All') }));
      }else{
        setIpCamera((prev) => ({ ...prev, [name]: value, camera_model: t('All') }));
      }
    }
  }, [setIpCamera, t]);

  return (
    <Container>
      <InputFieldContainer>
        <InputFieldBox>
          <TextFieldRequired>
            <TextField
              tabIndex={4}
              name='rtsp_url'
              label={t('RTSP URL')}
              placeholder='rtsp://192.168.100.1/'
              fieldState='default'
              onChange={onChangeHandler}
              maxLength={255}
              value={ipCamera.rtsp_url}
              autoComplete='off'
            />
          </TextFieldRequired>
          <BufferTextFieldRequired>
            <TextField
              tabIndex={5}
              name='buffer_time'
              label={t('Buffer Time')}
              fieldState='default'
              onChange={onChangeHandler}
              value={ipCamera.buffer_time}
            />
            <SecondText>{t('Second')}</SecondText>
          </BufferTextFieldRequired>
          <InputBox>
            <SelectField
              tabIndex={6}
              changeCallback={(event) => onChangeSelector(event === 'true', 'short_header')}
              defaultValue={ipCamera?.short_header?.toString()}
              label={{
                htmlFor: 'Short Header',
                text: t('Short Header')
              }}
            >
              <option value='false'>
                {t('No')}
              </option>
              <option value='true'>
                {t('Yes')}
              </option>
            </SelectField>
          </InputBox>

        </InputFieldBox>
        <InputFieldBox>
          <input type='password' style={{'display': 'none'}} />
          <TextField
            tabIndex={9}
            name='rtspURL'
            label={t('User Name')}
            fieldState='default'
            onChange={onChangeHandler}
            value={ipCamera.username}
            maxLength={255}
            autoComplete='off'
          />
          <PasswordContainer>
            <input type='password' style={{'display': 'none'}} />
            <UsePasswordField
              id='rtspData'
              name='rtspData'
              value={ipCamera.password}
              type='text'
              setInput={onChangeHandler}
              autoComplete='off'
              width='378px'
              height='40px'
              label={t('Password')}
            />
          </PasswordContainer>

          <TextBasicBox>
            <SwitchText htmlFor='' labelText={t('Low Latency Decode')} />

            <SwitchBox maxWidth={i18n.language === 'ja' ? '126px' : '163px'}>
              <Switch
                checked={ipCamera.low_latency_decode}
                leftTheme='off'
                onChangeCallback={() => setIpCamera(prev => ({ ...prev, low_latency_decode: !prev.low_latency_decode }))}
                rightTheme='on'
                state='default'
              />
            </SwitchBox>
          </TextBasicBox>
        </InputFieldBox>
      </InputFieldContainer>

      <TextConfigBox>
        <TextConfig htmlFor='' labelText={t('Optional Camera Meta')} />
        <Line />
      </TextConfigBox>
      <OptionalCameraBox>
        <InputFieldBox>
          <InputBox>
            <SelectField
              tabIndex={11}
              changeCallback={(event) => onChangeSelector(event, 'camera_manufacture')}
              defaultValue={(ipCamera.camera_manufacture === '' ? 'Other' : ipCamera.camera_manufacture) as string}
              label={{
                htmlFor: 'Camera Manufacturer',
                text: t('Camera Manufacturer')
              }}
              placeholder={t('Select Manufacturer…')}
            >
              {CAMERA_MANUFACTURER_LIST.map(({ name, value }: { name: string, value: string }) => (<option key={name} value={value}>{t(name)}</option>))}
            </SelectField>
          </InputBox>
          {(ipCamera.camera_manufacture === MANUFACTURER_HIKVISION || ipCamera.camera_manufacture === MANUFACTURER_AXIS ) &&
            <TextFieldRequired>
              <TextField
                tabIndex={12}
                name='camera_model'
                label={t('Camera Model')}
                fieldState='default'
                onChange={onChangeHandler}
                maxLength={255}
                onPaste={(e) => { e.preventDefault(); }}
                onCopy={(e) => { e.preventDefault(); }}
                value={`${t('All')}`}
                disabled
              />
            </TextFieldRequired>}
        </InputFieldBox>
        <InputFieldBox>
          <InputBox disable={ipCamera.camera_manufacture !== 'Other'}>
            <TextFieldRequiredLast>
              <TextField
                tabIndex={13}
                name='camera_web_ui_url'
                label={t('Camera\'s Web UI URL')}
                placeholder='https://10.10.10.11'
                fieldState='default'
                onChange={onChangeHandler}
                value={ipCamera.camera_web_ui_url}
                maxLength={255}
                autoComplete='off'
              />
            </TextFieldRequiredLast>
          </InputBox>
        </InputFieldBox>
      </OptionalCameraBox>
    </Container>
  );
});

export default IPCamera;