import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Label, ButtonWithLoading, useNotification, useModal } from 'scorer-ui-kit';
import styled from 'styled-components';
import { ENABLE_VIDEO_RECORDER } from '../../constants';
import { getConfigDetails, setSnapshotMP4Details } from 'services/apiConfig';
import { IAction } from 'interface';
import i18n from 'i18next';
import SnpshotModal from './SnapShotModal';

const AdvancedBox = styled.div`
  max-width: 940px;
  margin: 0px 2px 0 0;
`;

const AdvanceSection = styled.div<{ lang: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 80px;
  button{
    width: ${({ lang }) => lang === 'ja' ? '241px;' : '300px;'}
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const AdvanceSectionTwo = styled.div<{ lang: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 36px;
  button{
    width: ${({ lang }) => lang === 'ja' ? '241px;' : '300px;'}
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const AdvanceTextBox = styled.div`
  max-width: 63%;
`;

const AdvancedTitle = styled(Label)`
  font-size: 20px;
  color: #5a6269;
  margin: 0 0 8px 0px;
`;

const StatusTextEnabled = styled(Label)`
  font-size: 16px;
  color: #5a6269;
  margin: 0 0 8px 4px;
  color: rgb(92, 200, 121);
`;

const StatusTextDisabled = styled(Label)`
  font-size: 16px;
  color: #5a6269;
  margin: 0 0 8px 4px;
  color: hsla(0,0%,55.3%,1.000);
`;

const LinkUrl = styled.a`
  color: #0097ce;
`;

const Subtitle = styled.div`
  font-size: 14px;
  line-height: 1.79;
  font-weight: 500;
  white-space: pre-line;
`;

const DividerLine = styled.div`
  height: 1px;
  background-color: #eee;
  width: 65%;
  margin-top: 17px;
`;

const Formatter = styled.div`
  width: 578px;
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  align-items: center;
`;

const ButtonWithLoadingVideo = styled(ButtonWithLoading)`
  margin-top:0px;
  margin-right: -3px;
`;

const ButtonWithLoadingSnapShot = styled(ButtonWithLoading)`
  margin-top: 0px;
  margin-right: -3px;
`;

const TextSnapShot = styled(Label)`
  font-size: 12px;
`;

const FormatterBox = styled.div`
  display: flex;
  flex-direction:row;
  font-size:12px;
  justify-content: flex-end;
  padding-top:8px;
  margin-bottom:-8px;
`;

const FormatterBoxEmpty = styled.div`

`;

const Format = styled.div`
  display:flex;
  gap:10px;
`;

interface IConfiguration {
  save_mp4: boolean;
  save_snapshot: boolean;
}

interface IAdvanceTab{
  streamName: string;
  isEditCamera: boolean;
  periodicSnapshotCameraGet: number | undefined;
  setCameraUrl: (data: string | null) =>void;
  cameraUrl: string | null
}

const defaultConfiguration = {
  save_mp4: false,
  save_snapshot: false
};

const AdvanceTab: FC<IAdvanceTab> = ({ streamName, isEditCamera, periodicSnapshotCameraGet, setCameraUrl}) => {

  const { t } = useTranslation(['CommonDict']);
  const tRef = useRef(t);
  const [configurationDetails, setConfigurationDetails] = useState<IConfiguration>(defaultConfiguration);
  const [snapshotLoading, setSnapshotLoading] = useState<boolean>(false);
  const [mp4Loading, setMp4Loading] = useState<boolean>(false);
  const language = i18n.language === 'ja' ? 'en' : 'ja';
  const cloudText = i18n.language === 'ja' ? 'SCORER Cloud へのアップロード' : 'SCORER Cloud Upload';
  const fileText = i18n.language === 'ja' ? 'ファイル' : 'Files';
  const [periodicSnapshotCamera, setPeriodicSnapshotCamera] = useState<number | undefined>(periodicSnapshotCameraGet);
  const { createModal, setModalOpen } = useModal();

  const { sendNotification } = useNotification();
  const sendNotificationRef = useRef(sendNotification);
  const periodicSnapshotCameraRef = useRef(periodicSnapshotCamera);
  const lang = i18n.language === 'ja' ? 'ja' : 'en';

  useEffect(() => {
    setPeriodicSnapshotCamera(periodicSnapshotCameraGet);
  }, [periodicSnapshotCameraGet]);

  const parentCallBack= useCallback((item) =>{
    setPeriodicSnapshotCamera(item);
    periodicSnapshotCameraRef.current = item;
  }, []);

  const getTrafficDetails = useCallback(async () => {
    try {
      const res = await getConfigDetails(streamName);
      if (res.data.status_code === 0) {
        setCameraUrl(res.data.data.camera_uri);
        const configuration:IConfiguration  = {
          save_mp4: res.data.data.save_mp4,
          save_snapshot: res.data.data.save_snapshot
        };
        setConfigurationDetails(configuration); 
      } else {
        sendNotificationRef.current({
          type: 'error',
          message: tRef.current('Failed to fetch settings')
        });
      }
    } catch (error) {
      sendNotificationRef.current({
        type: 'error',
        message: tRef.current('Failed to fetch settings')
      });
      console.error(error);
    }
  }, [setCameraUrl, streamName]);
  
  useEffect(() => {
    if(isEditCamera && streamName){
      getTrafficDetails();
    }
  }, [getTrafficDetails, isEditCamera, streamName]);

  const onSnapshotSaving = useCallback(async(saveSnapshot: boolean) => {
    setSnapshotLoading(true);
    const payload: IAction = {
      'action': saveSnapshot ? 'enable_periodic_snapshot' : 'disable_periodic_snapshot',
      'data': {'periodic_snapshot_interval': periodicSnapshotCameraRef.current ?? 60 }
    };
    try {
      const res = await setSnapshotMP4Details(streamName, payload);
      if(res.data.status_code === 200) {
        setSnapshotLoading(false);
        setConfigurationDetails({...configurationDetails, save_snapshot: saveSnapshot});
        sendNotificationRef.current({ 
          type: 'success',
          message: t('Snapshot saving updated successfully')
        });
      } else {
        setSnapshotLoading(false);
        setConfigurationDetails({...configurationDetails, save_snapshot: !saveSnapshot});
        sendNotificationRef.current({ 
          type: 'error',
          message: t('Failed to update snapshot saving')
        });
      }
    }
    catch (error) {
      console.error(error);
      setConfigurationDetails({...configurationDetails, save_snapshot: !saveSnapshot});
      sendNotificationRef.current({ 
        type: 'error',
        message: t('Failed to update snapshot saving')
      });
      setSnapshotLoading(false);
    }
    setModalOpen(false);
  }, [sendNotificationRef, streamName, t, configurationDetails, setModalOpen, periodicSnapshotCameraRef]);

  const onMP4Saving = useCallback(async(saveMp4: boolean) => {
    setMp4Loading(true);
    const payload: IAction = {
      'action': saveMp4 ? 'enable_save_mp4' : 'disable_save_mp4',
      'data': {}
    };
    try {
      const res = await setSnapshotMP4Details(streamName, payload);
      if(res.data.status_code === 200) {
        setConfigurationDetails({...configurationDetails, save_mp4: saveMp4});
        setMp4Loading(false);
        sendNotificationRef.current({ 
          type: 'success',
          message: t('Video recording updated successfully')
        });
      } else {
        setConfigurationDetails({...configurationDetails, save_mp4: !saveMp4});
        setMp4Loading(false);
        sendNotificationRef.current({ 
          type: 'error',
          message: t('Failed to update video recording')
        });
      }
    } 
    catch (error) {
      console.error(error);
      setConfigurationDetails({...configurationDetails, save_mp4: !saveMp4});
      sendNotificationRef.current({ 
        type: 'error',
        message: t('Failed to update video recording')
      });
      setMp4Loading(false);
    }
  }, [sendNotificationRef, streamName, t, configurationDetails]);

  const onClickLastExport = useCallback((data) => {
    if(!data) {
      onSnapshotSaving(data);
      return;
    }
    createModal({
      isCloseEnable: true,
      width: '580px',
      padding: true,
      closeText: t('CLOSE'),
      customComponent: <SnpshotModal parentCallBack={parentCallBack} periodicSnapshotCamera={periodicSnapshotCamera} setModalOpen={setModalOpen} onSnapshotSaving={onSnapshotSaving} data={data} />
    });
  }, [createModal, t, onSnapshotSaving, periodicSnapshotCamera, setModalOpen, parentCallBack]);

  return (
    <AdvancedBox>
      {ENABLE_VIDEO_RECORDER &&
        <>
          <AdvanceSection lang={language}>
            <AdvanceTextBox>
              <Formatter>
                <Format>
                  <AdvancedTitle htmlFor='' labelText={t('Snapshot Saving')} />
                  {configurationDetails.save_snapshot ?
                    <FormatterBox lang={lang}>
                      <TextSnapShot htmlFor='' labelText={t('Interval')} /> 
                      <div>: {periodicSnapshotCamera + ' ' + t('mins')}</div>
                    </FormatterBox>:
                    <FormatterBoxEmpty>
                      <TextSnapShot htmlFor='' labelText={t('')} />
                      <div />
                    </FormatterBoxEmpty>}
                </Format>
              
                {configurationDetails.save_snapshot ?
                  <div>
                    <StatusTextEnabled htmlFor='' labelText={t('Enabled')} />
                  </div>
                  :
                  <StatusTextDisabled htmlFor='' labelText={t('Disabled')} />}
              </Formatter>

              <Subtitle>
                <Trans t={t} i18nKey='The camera video image is periodically saved as image data. The image data can be viewed in the <1>{{fileText}}</1> tab of this camera.'>The camera video image is periodically saved as image data. The image data can be viewed in the <LinkUrl href={`/cameras/camera-details/${streamName}/overview?selectedTab=files`}>{{fileText}}</LinkUrl> tab of this camera.</Trans>
              </Subtitle>
            </AdvanceTextBox> 
            <ButtonWithLoadingSnapShot design={configurationDetails.save_snapshot ? 'secondary' : 'primary'} disabled={mp4Loading} loading={snapshotLoading} title={configurationDetails.save_snapshot ? t('Disable Snapshot Saving') : t('Enable Snapshot Saving')} onClick={() =>  onClickLastExport(!configurationDetails.save_snapshot)}>{configurationDetails.save_snapshot ? t('Disable Snapshot Saving') : t('Enable Snapshot Saving')}</ButtonWithLoadingSnapShot>
          </AdvanceSection>
          <DividerLine />
        </>}

      {ENABLE_VIDEO_RECORDER &&
        <>
          <AdvanceSectionTwo lang={language}>
            <AdvanceTextBox>
              <Formatter>
                <AdvancedTitle htmlFor='' labelText={t('Video Recording')} />
                {configurationDetails.save_mp4 ?
                  <StatusTextEnabled htmlFor='' labelText={t('Enabled')} />:
                  <StatusTextDisabled htmlFor='' labelText={t('Disabled')} />}
              </Formatter>
              <Subtitle>
                <Trans t={t} i18nKey='This will enable video recording service. Please also enable <1>{{cloudText}}</1> so that you can archive / utilize the recorded video in SCORER Cloud.'>This will enable video recording service. Please also enable <LinkUrl href='/settings/cloud-settings'>{{cloudText}}</LinkUrl> so that you can archive / utilize the recorded video in SCORER Cloud.</Trans>
              </Subtitle>
            </AdvanceTextBox>
            <ButtonWithLoadingVideo design={configurationDetails.save_mp4 ? 'secondary' : 'primary'} title={configurationDetails.save_mp4 ? t('Disable Video Recording') : t('Enable Video Recording')} disabled={snapshotLoading} loading={mp4Loading} onClick={() => onMP4Saving(!configurationDetails.save_mp4)}>{configurationDetails.save_mp4 ? t('Disable Video Recording') : t('Enable Video Recording')}</ButtonWithLoadingVideo>
          </AdvanceSectionTwo>
          <DividerLine />
        </>}
    </AdvancedBox>
  );
};

export default AdvanceTab;
