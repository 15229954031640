import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Content, Label, PageHeader, Spinner, Input, ButtonWithLoading, AlertBar, Icon, useModal, ButtonWithIcon, Button } from 'scorer-ui-kit';
import { deleteSafieSettings, getSafieConfigDetails, getSafieStreamDetails, setSafieSettings } from '../services/apiConfig';
import { SpinnerContainer } from 'style';
import styled from 'styled-components';
import { DATE_FORMAT_WITHOUT_SEC, DEFAULT_SAFIE_CONNECTION_DATA } from '../constants';
import SafieConfirmModal from './SafieConfirmModal';
import AdvanceSafieInegration from './AdvanceSafieIntegration';
import { format } from 'date-fns';
import { useHistory } from 'react-router';

const MainContainer = styled(Content)`
  width: 100%;
  padding: 62px 30px 62px 90px;
  @media screen and (min-width: 1440px) {
    padding: 62px 25px 62px 170px;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 960px !important;
`;

const Header = styled.div`
  margin-top: 20px;
  white-space: pre-line;
  width: 100%;
  max-width: 600px !important;
  line-height: 1.2;
  > div:first-child > p {
    font-weight: normal;
    font-style: normal;
  }
`;

const MainContent = styled.div`
  margin-top: 40px;
`;

const InfoHeading = styled.div`
  margin-bottom: 24px;
  font-size: 22px;
  font-weight: 500;
  padding-left: 40px;
`;

const AdvanceHeading = styled.div`
  margin-bottom: -8px;
  font-size: 16px;
  font-weight: 500;
  padding-left: 3px;
  margin-top: 50px;
  margin-right:16px;
`;

const SetupIntro = styled.div`
  font-size: 15.8px;
  line-height: 2.1;
`;

const SetupContainer = styled.div`
  width: 100%;
  max-width: 937px;
  padding: 35px 10px 30px 40px;
  border: solid 1px #eee;
`;

const SetupContainer2 = styled(SetupContainer)`
  margin-bottom: 25px;
  > ${SetupIntro} {
    max-width: 750px;
  }
`;

const SetupHeading = styled.div`
  font-size: 17.5px;
  font-weight: 600;
  margin-bottom: 16px;
`;


const SafieLinkButtonContainer = styled.div`
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px 0;
  margin-top: 26px;
`;

const InputContainer = styled.div`
  width: 100%;
  max-width: 738px;
  margin-top: 33px;
  display: flex;
  gap: 0 20px;
  & > div {
    width: 100%;
  }
  & > button {
    flex-shrink: 0;
  }
`;

const AlertBarContainer = styled.div`
  max-width: 937px !important;
  margin: 54px 0 -4px 0;
`;

const SuccessIconContainer = styled.div`
  & > div {
    margin-bottom: 21px;
  }
`;

const DetailsTable = styled.table`
  border-collapse: collapse;
  font-size: 14px;
  margin-top: 9px;
  line-height: 1.8;
  & > tr > td {
    padding: 2px 0px;
  }
  & > tr > #spacer {
    width: 45px;
  }
`;

const ButtonContainer = styled.div`
  margin: 40px 10px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ResetButton = styled(ButtonWithLoading)`
  background: #e4edf4;
  :hover:enabled {
    background: #d4e4f0;
  }
  :disabled {
    background: #e9f0f6;
  }
`;

const AddSafieButton = styled(Button)`
 white-space:nowrap;
`;

const Line = styled.div`
  background-color: #eee;
  height: 1px;
  width: 50%;
  margin-top: 62px;
`;

const Box = styled.div`
  display:flex;
  flex-direction: row;
`;

const ButtonWithIconFormatter = styled(ButtonWithIcon)`
  margin-top: 40px;
  margin-left: 40px;
  height:34px;
  font-size:12px;
`;

interface IConnectionData {
  type: string;
  enabled: boolean;
  configuration_time: string;
  refresh_token: string;
  client_id: string;
  client_secret: string;
  access_token_status: string;
}

const SafieIntegration: React.FC = () => {
  const {push} = useHistory();
  const [alert, setAlert] = useState<IAlert|null>(null);
  const [connectionData, setConnectionData] = useState<IConnectionData>(DEFAULT_SAFIE_CONNECTION_DATA);
  const [isConfigured, setIsConfigured] = useState(false);
  const [isStreamPresent, setStreamPresent] = useState(false);
  const [otp, setOtp] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const { t } = useTranslation(['CommonDict']);
  const { createModal, setModalOpen } = useModal();
  const [showAdvance, setShowAdvance] = useState<boolean>(false);
  const ref:any = useRef(null);
  const [configuredTime, setConfiguredTime] = useState<Date | string>('');
  const [isSafieStatusBad,setSafieStatusBad]=useState<boolean>(false);

  const checkAllValuesPresent = useCallback((obj) => {
    const desiredObj = ['type', 'enabled', 'configuration_time', 'refresh_token', 'client_id', 'client_secret'];
    let isAllValuesPresent = false;   
    isAllValuesPresent = Object.values(obj).every((item) => {
      if(item===''){
        return false;
      } else {
        return true;
      }
    }) && desiredObj.every((item) => {
      if(!Object.keys(obj).includes(item)){
        return false;
      } else {
        return true;
      }
    });
    return isAllValuesPresent;
  },[]);

  
  const isEmpty = useCallback((val) => {
    return (val === undefined || val.trim() === '');
  }, []);

  const getStreamList = useCallback(async () => {
    try {
      const {data: { data : { stacks } } } = await getSafieStreamDetails();
      Object.keys(stacks).forEach((stack_name) => {
        if(stacks[stack_name].action === 'DeleteStack'){
          delete stacks[stack_name];
        } else if(stacks[stack_name].services.recorder1.camera_type !== 'SAFIE'){
          delete stacks[stack_name];
        }
      });
      if(Object.keys(stacks).length === 0){
        setStreamPresent(false);
      } else {
        setStreamPresent(true);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getConfigDetails = useCallback(async () => {
    setLoading(true);
    try {
      const {data: { data } } = await getSafieConfigDetails();
      if (data !== null && Object.keys(data).length !== 0) {
        const inputs = Object.assign({}, data.services.safie_settings1);
        if(checkAllValuesPresent(inputs)){
          setConnectionData(inputs);
          setLoading(false);
          setIsConfigured(true);
          if(data.action === 'DeleteStack') {
            if(data.action_status.status === 'completed') {
              setConnectionData(DEFAULT_SAFIE_CONNECTION_DATA);
              setIsConfigured(false);
              setOtp('');
            }
          }
        } else {
          setConnectionData(DEFAULT_SAFIE_CONNECTION_DATA);
          setLoading(false);    
        }
      } else {
        setConnectionData(DEFAULT_SAFIE_CONNECTION_DATA);
        setLoading(false);
      }

      if(data.action !== 'DeleteStack' && data.services && data.services.safie_settings1 && data.services.safie_settings1.access_token_status && data.services.safie_settings1.access_token_status === 'NG'){
        setSafieStatusBad(true);
      }else{
        setSafieStatusBad(false);
      }
    } catch (error) {
      setSafieStatusBad(false);
      setLoading(false);
    }
  }, [checkAllValuesPresent]);

  useEffect(() => {
    getConfigDetails();
    getStreamList();
  }, [getConfigDetails, getStreamList]);
  
  const handleOtpChange = useCallback((e)=>{
    setOtp(e.target.value);
  },[]);

  const onSave = useCallback(() => {
    setLoadingSave(true);

    const finalJson = {
      type: 'safie',
      namespace: 'system',
      enabled: connectionData.enabled,
      services: {
        safie_settings1: {
          enabled: true,
          type: 'safie_settings',
          type_of_authorization: 'otp',
          otp: ''
        }
      }
    };
    if (isEmpty(otp)) {
      setLoadingSave(false);
      setAlert({key: new Date(), message: t('Please enter One-time Password'), type: 'error'});
      return;
    } else {
      finalJson.services.safie_settings1.otp = otp;
    }
    setSafieSettings(finalJson)
      .then(() => {
        setAlert({key: new Date(), message: t('Safie Integration configured successfully'), type: 'success'});
        setLoadingSave(false);
        getConfigDetails();
      })
      .catch(error => {
        setLoadingSave(false);
        if(error.response){
          setAlert({key: new Date(), message: t('There was an error with your Safie integration one-time password (OTP). Please check you copied and pasted the password correctly and generate a new OTP is required.'), type: 'error'});
        }else{
          setAlert({key: new Date(), message: t('Unable to connect to the edge-api service'), type: 'error'});
        }
      });
  }, [connectionData, otp, getConfigDetails, isEmpty, t]);

  const onRefresh = useCallback(async ()=>{
    setLoadingDelete(true);
    try {
      const { data: { data } } = await getSafieConfigDetails();
      if(data.action === 'DeleteStack') {
        if(data.action_status.status === 'completed') {
          setLoadingDelete(false);          
          setIsConfigured(false);
          setConnectionData(DEFAULT_SAFIE_CONNECTION_DATA);
          setAlert({key: new Date(), message: t('Safie Integration reset successfully'), type: 'success'});
          setOtp('');
        } else if(data.action_status.status === 'failed') {
          setAlert({key: new Date(), message: t('Safie Integration reset request failed'), type: 'error'});
          setLoadingDelete(false);          
        } else {
          onRefresh();
        }
      }
      if(data.action !== 'DeleteStack' && data.services && data.services.safie_settings1 && data.services.safie_settings1.access_token_status && data.services.safie_settings1.access_token_status === 'NG'){
        setSafieStatusBad(true);
      }else{
        setSafieStatusBad(false);
      }
    } catch (error) {
      setLoadingDelete(false);
    }
  },[t]);

  const onReset = useCallback(() => {
    setModalOpen(false);
    setLoadingDelete(true);
    deleteSafieSettings()
      .then(() => {
        setOtp('');
        onRefresh();
      })
      .catch(error => {
        setLoadingDelete(false);
        if(error.response){
          const errorObj = error.response.data;
          setAlert({key: new Date(), message: errorObj.error.message, type: 'error'});
        }else{
          setAlert({key: new Date(), message: t('Unable to connect to the edge-api service'), type: 'error'});
        }
      });
  }, [onRefresh, setModalOpen, t]);

  // Confirm Modal
  const getConfirmationModal = useCallback(() => {
    return (
      <SafieConfirmModal onReset={onReset} />
    );
  }, [onReset]);

  const onClickReset = useCallback(() => {
    const confirmModal: ReactElement = getConfirmationModal();
    createModal({
      isCloseEnable: true,
      closeText: t('CLOSE'),
      width: 'max-length',
      padding: false,
      customComponent: confirmModal,
    });
  }, [t, createModal, getConfirmationModal]);

  const openResetModal = useCallback(() => {    
    getStreamList();
    if (isStreamPresent) {
      setAlert({key: new Date(), message: t('Please delete all Safie streams before resetting Safie integration'), type: 'error'});
      return;
    }
    setModalOpen(true);
    onClickReset();
  }, [isStreamPresent, getStreamList, setModalOpen, onClickReset, t]);

  useEffect(() => {
    setTimeout(() => {
      if (alert) {
        setAlert(null);
      }
    }, 5000);
  }, [alert]);

  const handleClick = () => {
    setShowAdvance(true);
    setTimeout(function(){
      ref.current?.scrollIntoView();
    }); 
  };

  const onAddSafieCam = useCallback(() => {
    localStorage.setItem('redirectFromSafie', 'true');
    push('/add-camera');
  }, [push]);

  useEffect(() => {
    if (connectionData.configuration_time !== '') {
      const d = new Date(0);
      d.setUTCSeconds(parseFloat(connectionData.configuration_time));
      setConfiguredTime(d);
    }
  }, [connectionData.configuration_time]);

  return (
    <MainContainer>
      {!loading ? (
        <Container>
          <Header>
            <PageHeader
              title={t('Safie Integration')}
              areaTitle={t('Settings')}
              icon='ViewSettings'
              introductionText={t(
                'These settings are used to authorize and connect to your Safie account. This will enable you to add Safie cameras for use in the application. A SCORER Cloud Biz account is required.'
              )}
              updateDocTitle={false}
            />
          </Header>
          {
            alert && 
              <AlertBarContainer onClick={()=>{setAlert(null);}}>
                <AlertBar message={alert?.message} type={alert?.type} hideCloseButton />
              </AlertBarContainer>
          }
          <MainContent>
            {
              isConfigured ?
                <SetupContainer>
                  <SuccessIconContainer>
                    <Icon icon={isSafieStatusBad?'Warning':'Success'} size={35} color={isSafieStatusBad?'danger':'primary'} />
                  </SuccessIconContainer>
                  <SetupHeading>{isSafieStatusBad?t('Connection with Safie service failed. Please reset the integration.'):t('Safie Integration Is Ready')}</SetupHeading>
                  {/* <SetupIntro>{t('One-Time Password Details')}:</SetupIntro> */}
                  <DetailsTable>
                    <tr>
                      <td>{t('Configured On')}:</td>
                      <div id='spacer' />
                      <td>
                        {
                          configuredTime === '' ? ''
                            : (format(new Date(configuredTime), DATE_FORMAT_WITHOUT_SEC) + ' JST')
                        }
                      </td>
                    </tr>
                  </DetailsTable>
                  <ButtonContainer>
                    <ResetButton
                      loading={loadingDelete} 
                      design='secondary'
                      onClick={openResetModal}
                    >{t('Reset Integration')}
                    </ResetButton>
                    <AddSafieButton
                      disabled={isSafieStatusBad}
                      design='primary'
                      onClick={onAddSafieCam}
                    >{t('Add Safie Camera')}
                    </AddSafieButton>
                  </ButtonContainer>
                </SetupContainer>
                :
                <>
                  <InfoHeading>{t('How To Setup Safie Integration')}</InfoHeading>
                  {
                    !isConfigured && 
                      <SetupContainer2>
                        <SetupHeading>{t('Step 1- Generate your Safie Connector One-Time Password.').replace('-',':')}</SetupHeading>
                        <SetupIntro>
                          {t('You can use our service here to generate your one-time password to complete integration. The steps are simple to follow and once complete you copy and paste your new OTP in the step 2 below.')}
                        </SetupIntro>
                        <SafieLinkButtonContainer>
                          <ButtonWithIcon icon='ExternalLink' position='left' onClick={()=>{window.open('https://safie-auth.scorer.jp');}}>
                            {t('Open Safie Connector OTP Site')}
                          </ButtonWithIcon>
                        </SafieLinkButtonContainer>
                      </SetupContainer2>
                  }
                  <SetupContainer>
                    <SetupHeading>{t('Step 2- Paste and save your new OTP below.').replace('-',':')}</SetupHeading>
                    <SetupIntro>
                      {t('Once you have generated and copied your Safie Connector one-time password, paste it below and save to complete your integration.')}
                    </SetupIntro>
                    <InputContainer>
                      <Input type='text' placeholder={t('Enter your One-Time Password...')} value={otp} onChange={handleOtpChange} />
                      <ButtonWithLoading
                        loading={loadingSave}
                        design='primary'
                        onClick={onSave}
                        disabled={otp === ''}
                      >{t('Save')}
                      </ButtonWithLoading>
                    </InputContainer>
                  </SetupContainer>

                  {showAdvance ?
                    <>
                      <Box ref={ref}>
                        <AdvanceHeading>{t('Advanced')}</AdvanceHeading>
                        <Line />
                      </Box>
                      <AdvanceSafieInegration otp={otp} getConfigDetails={getConfigDetails} />
                    </> : 
                    <ButtonWithIconFormatter
                      design='secondary'
                      icon='LightMode'
                      onClick={() => { handleClick(); }}
                      position='left'
                      size='normal'
                    >
                      {t('Directly Enter the Credentials')}
                    </ButtonWithIconFormatter>}
                </>
            }
          </MainContent>
        </Container>
      ) : (
        <Container>
          <SpinnerContainer>
            <Spinner size='large' styling='primary' />
            <Label htmlFor='loader' labelText={t('Loading') + '...'} />
          </SpinnerContainer>
        </Container>
      )}
    </MainContainer>
  );
};

export default SafieIntegration;