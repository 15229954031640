import React, { FC, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonWithIcon, ButtonWithLoading, Content, Icon, Label, PageHeader, SelectField, Spinner, Switch, Tabs, TextAreaField, TextField, useModal, useNotification, TabList, Tab, TabContent } from 'scorer-ui-kit';
import styled, { css } from 'styled-components';
import IPCamera from './AddCamera/IPCamera';
import RTMPCamera from './AddCamera/RTMPCamera';
import HLSCamera from './AddCamera/HLSCamera';
import { CAMERA_TYPE } from '../constants';
import { useHistory, useLocation } from 'react-router-dom';
import i18n from 'i18n';
import { addCamera, deleteSingleCamera, getSingleCamera, updateCamera, getVideoDevice, getSafieDevice, getSafieConfig, getAllCamera, getConfigDetails } from 'services/apiConfig';
import ModelBox from 'components/ModelBox';
import AuthService from 'services/authService';
import { isEqual } from 'lodash';
import AdvanceTab from './CameraConfiguration/AdvanceTab';
import { Divider } from 'Styles';
import {cloneDeep} from 'lodash';

const MainContainer = styled(Content)`
  width: 100%;
  padding: 62px 30px 62px 90px;
  @media screen and (min-width: 1440px) {
    padding: 62px 25px 62px 170px;
  }
  select{
    background-color: #fff;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 943px !important;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 862px;
  > div:first-child > div > a {
    max-width: 725px;
    overflow-wrap: break-word;
    white-space: break-spaces;
    bottom: 34.5px;
    top: unset;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const HeaderButton = styled.div`
  display: flex;
  margin-top: 2px;
  & > button:first-child {
    margin-right: 20px;
    background: #e4edf4;
  }
`;

const BaseConfig = styled.div`
  margin: 20px 0;
  max-width: 1020px;
`;

const TextConfigBox = styled.div`
  position: relative;
`;

const TextBasicBox = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-top: 43px;
  max-width: 862px;
  // justify-content: flex-start;
`;

const TextConfig = styled(Label)`
  position: absolute;
  font-size: 16px;
  color: #5a6269;
  background: #f9fafb;
  z-index: 1;
  padding-right: 18px;
`;

const Line = styled.div`
  position: absolute;
  height: 1px;
  top: 12px;
  width: 100%;
  background-color: #eee;
`;

const InputFieldContainer = styled.div`
  display: flex;
  position: relative;
  top: 6px;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 862px;
`;

const InputFieldBox = styled.div`
  margin-top: 20px;
  min-width: 380px;
`;

const NotesBox = styled.div<{showNotes: boolean}>`
  margin-top: ${({ showNotes }) => showNotes ? '2px' : '12px'};
  max-width: 862px;
  textarea{
    resize: none;
    min-width: 200px;
    max-width: 100%;
    min-height: 96px;
    max-height: 96px;
    @media (max-width: 1175px) {
      max-width: 380px;
    }
  }
`;

const InputBox = styled.div<{ blur?: boolean }>`
  margin: 20px 0;
  ${({ blur }) => blur && css`
    span{
      opacity: 0.5;
    }
  `}
`;

const VideoConfigBox = styled.div`
  margin: 63px 0;
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 89vh;
  align-items: center;
  justify-content: center;
`;

const SafieSpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 25vh;
  align-items: center;
  justify-content: center;
  margin-bottom: -154px;
`;

const SpinnerBoxWebCam = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 30vh;
  align-items: center;
  justify-content: center;
  margin-bottom: -154px;
`;


const WebFieldContainer = styled.div`
  position: relative;
  top: 53px;
`;

const WebFieldContainerSafie = styled.div`
  position: relative;
  top: 53px;
  width: 100%;
  max-width: 380px;
`;

const SafieBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 230px;
  border: solid 1px #eee;
  position: relative;
  top: 77px;
  align-items: center;
  justify-content: center;
`;

const SafieText = styled(Label)`
  font-size: 20px;
  margin-bottom: 0;
`;

const SafieIntro = styled(Label)`
  white-space: pre-line;
  text-align: center;
  font-size: 14px;
  margin-bottom: 23px;
`;

const TextFieldRequired = styled.div`
  span::after{
    font-weight: bold;
    content: " *";
    color: rgb(238, 75, 43);
  }
  width: 382px;
`;

const TextFieldRequiredSource = styled.div`
  span::after{
    font-weight: bold;
    color: rgb(238, 75, 43);
  }
  width: 382px;
`;

const SwitchBox = styled.div<{ maxWidth: string }>`
  display: flex;
  min-width: ${({ maxWidth }) => maxWidth};
`;

const SwitchText = styled(Label)`
  margin-left: 10px;
  line-height: 22px;
  text-align: left;
  font-size: 14px;
  margin-bottom: 0;
  span{
    margin-bottom: 0;
  }
`;

const AdvancedDetailsBox = styled.div`
  margin-top: 0px;
`;

const AdvancedContainer = styled.div`
  display: flex;
  position: relative;
  top: -20px;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 944px;
`;

const AdvancedBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom:100px;
  margin-top:4px;
`;

const AdvanceTextBox = styled.div`
  margin-top: 25px;
  width: 59%;
`;

const AdvancedTitle = styled(Label)`
  font-size: 20px;
  color: #5a6269;
  margin-bottom: 0;
`;

const DeleteButton = styled(Button)`
  margin: 26px 0 0 0;
`;

const ButtonFormatter = styled.div<{ lang: string }>`
  button{
    width: ${({ lang }) => lang === 'ja' ? '241px;' : '300px;'};
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const AdvancedTitleSubtitle = styled(Label)`
  font-size: 14px;
`;

const ModelTextConfirm = styled(Label)`
  margin-bottom: 0;
  color:#7c7e7f;
  span{
    margin-bottom: 0;
  }
`;

const ModelText = styled(Label)`
  margin-bottom: 0;
  color:#8f8f8f;
  span{
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const DelModalLine = styled.div`
  width: 100%;
  height: 1px;
  margin: 18px 2px 18px 2px;
  background-color: #eee;
`;

const ErrorBox = styled.div<{bgColor: string}>`
  margin-top: 67px;
  padding: 10px 13px;
  margin-bottom: 14px;
  width: 100%;
  height: max-content;
  min-height: 46px;
  display: flex;
  align-items: center;
  gap: 0 10px;
  background: ${({bgColor}) => bgColor};
  > div {
    display: flex;
  }
`;

const ErrorContainer = styled.div`
  flex-direction: column;
  gap: 0 3px;
  color: #fff;
`;

const ErrorDescription = styled.div`
  font-size: 12px;
`;

const StyledDivider = styled(Divider)`
  max-width: 1010px;
`;

const AdvanceSectionCameraWebUI = styled.div<{ lang: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 36px;
  button{
    width: ${({ lang }) => lang === 'ja' ? '241px;' : '300px;'};
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const AdvancedSubtitle = styled(Label)`
  font-size: 14px;
  line-height: 1.79;
`;

const Formatter = styled.div`
  display: flex;
  gap: 100px;
`;

const FormatterBox = styled.div`
`;

const TextBasicConfig = styled(Label)`
  font-size: 16px;
  color: #5a6269;
  background: #f9fafb;
  z-index: 1;
  padding-right: 18px;
  white-space: nowrap;
  margin-bottom: 0;
  span {
    margin-bottom: 0;
  }
`;

const AreaContainer = styled.span`
  height: 15px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 12px;
  font-weight: 500;
  color: #767676;
  cursor: pointer;
  max-width: 550px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    border-bottom: 1px solid #767676;;
    box-sizing: border-box;
    width: max-content;
  }
`;

const PageHeaderComponent = styled.div`
  display: flex;
  flex-direction: column;
`;

const LineBasic = styled.div`
  height: 1px;
  width: 74%;
  background-color: #eee;
  margin-right: 16px;
`;

const basicConfigData = {
  camera_name: '',
  camera_type: localStorage.getItem('redirectFromSafie') === 'true' ? 'SAFIE' : '',
  notes: '',
  camera_enabled: true
};

const basicConfigDataTemp = {
  camera_name: '',
  camera_type: '',
  notes: '',
  camera_enabled: true
};

const ipCameraData = {
  rtsp_url: '',
  buffer_time: '1',
  short_header: false,
  username: '',
  password: '',
  camera_manufacture: '',
  camera_model: '',
  camera_web_ui_url: '',
  packet_delay_type: 'auto',
  low_latency_decode: true,
};

const rtmpCameraData = {
  rtmp_server_type: 'Internal',
  rtmp_source_url: '',
  rtmp_stream_key: '',
  rtmp_url_type: true
};


interface ListItem {
  name: string;
  value: string;
}

interface IBasicConfig {
  camera_name: string,
  camera_type: string,
  notes: string,
  camera_enabled: boolean;
}

interface IVideo {
  name: string;
  description: string
}

interface ISafieDeviceMap {
  device_id: string,
  serial: string,
  setting: {
    name: string
  },
  model: {
    description: string
  },
  status: {
    video_streaming: boolean
  }
}

interface IshowCameraError {
  name: string,
  value: string,
  video_streaming: boolean,
}


const AddCamera: FC = () => {
  const [isMount, setIsMount] = useState(true);
  const [basicConfig, setBasicConfig] = useState<IBasicConfig>(basicConfigData);
  const [ipCamera, setIpCamera] = useState(ipCameraData);
  const [hlsUrl, setHlsURL] = useState('');
  const [rtmpCamera, setRtmpCamera] = useState(rtmpCameraData);
  const [loader, setLoader] = useState(false);
  const [videoDeviceList, setVideoDeviceList] = useState<ListItem[]>([]);
  const [usbDevice, setUsbDevice] = useState({ name: '', description: '' });
  const [sourceType, setSourceType ] = useState('UVC_MJPG');
  const sourceTypeList = ['UVC_MJPG', 'UVC_I420'];
  const sourceTypeDisplayList = ['MJPEG', 'I420'];
  const [safieDeviceName, setSafieDeviceName] = useState('');
  const [safieCameraList, setSafieCameraList] = useState<ListItem[]>([]);
  const { t } = useTranslation(['CommonDict']);
  const tRef = useRef(t);
  const postRef: any = useRef(null);
  const { sendNotification } = useNotification();
  const notificationRef = useRef(sendNotification);
  const { pathname, search } = useLocation();
  const [isEditCamera] = useState<boolean>(pathname === '/edit-camera');
  const [editLoading, setEditLoading] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const { createModal, setModalOpen } = useModal();
  const { goBack, push } = useHistory();
  const [streamName, setStreamName] = useState('');
  const [editButtonLoading, setEditButtonLoading] = useState(false);
  const [safieConfiguration, setSafieConfiguration] = useState<boolean | undefined>();
  const [serverUrlTypeRTMP, setServerUrlTypeRTMP] = useState<string>('');
  const [safieCameraUsedList, setSafieCameraUsedList] = useState<string[] | undefined>([]);
  const [showCameraError, setShowCameraError] = useState<IshowCameraError[]>([]);
  const [showCameraErrorBoolean, setShowCameraErrorBoolean] = useState<boolean>();
  const [prevValues, setPrevValues] = useState<any>();
  const prevCompareVal = useRef<any>();
  const [compareNewValues, setCompareNewValues] = useState<any>();
  const [safieLoader, setSafieLoader] = useState<boolean>(false);
  const [safieDeviceNameForComparision, setSafieDeviceNameForComparision] = useState<string>('');
  const language = i18n.language === 'ja' ? 'en' : 'ja';
  const [periodicSnapshotCameraGet, setPeriodicSnapshotCameraGet] = useState<number | undefined>(undefined);
  const [showNoDevice, setShowNoDevice] = useState(false);
  const [showNoDeviceSafie, setShowNoDeviceSafie] = useState(false);
  const [selectedTab, setSelectedTab] = useState<string>('basic');
  const history = useHistory();
  const [cameraUrl, setCameraUrl] = useState<string | null>('');
  const params = useLocation().search;
  const [ historyParams ] = useState<string>(params);
  const sendNotificationRef = useRef(sendNotification);

  const fetchHistoryParams = useCallback(() => {
    if(historyParams.endsWith('advanced')){
      setSelectedTab('advanced');
    }  
    if(historyParams.endsWith('basic')){
      setSelectedTab('basic');
    }  
  }, [ historyParams, setSelectedTab ]);

  useEffect(() => {
    fetchHistoryParams();
  }, [ fetchHistoryParams ]);

  useEffect(() => {
    if (isMount) {
      if (localStorage.getItem('redirectFromSafie') === 'true') {
        setBasicConfig(prev => {
          const temp = cloneDeep(prev);
          temp.camera_type = 'SAFIE';
          return temp;
        });
        localStorage.removeItem('redirectFromSafie');
        setIsMount(false);
      }
    }
  }, [isMount]);

  useEffect(()=>{
    const data = {...basicConfig, ...usbDevice, safieDeviceName};
    setCompareNewValues(data);
  }, [usbDevice, basicConfig, safieDeviceName]);

  const getsetCameraUrl = useCallback((data)=>{
    setCameraUrl(data);
  }, []);

  const onCameraWebUI = useCallback(() => {
    if(typeof(cameraUrl) === 'string'){
      window.open(cameraUrl as string, 'blank');
    }
  }, [cameraUrl]);

  const fetchAllCamera = useCallback(async () => {
    try {
      const { data: { status_code, data } } = await getAllCamera(0);
      if (status_code === 200) {
        Object.keys(data).map((item) => {
          if(data[item].camera_type !== 'SAFIE' ){
            delete data[item];
          }
          return item;            
        });
        const list: string[] = [];
        Object.keys(data).map((item) => {
          list.push(data[item].video_configuration.camera_id);
          return item;          
        });           
        setSafieCameraUsedList(list);
      }
    } catch (error) {
      console.error((error as Error).message);
    }
  }, []);

  useEffect(() => {
    fetchAllCamera();
  }, [fetchAllCamera]);

  const fetchVideoDevice = useCallback(async (deviceObj) => {
    setLoader(true);
    try {
      const { data: { status_code, data } } = await getVideoDevice();
      if (status_code === '10000') {
        if(data.available_devices.length === 0){
          setShowNoDevice(true);
        }
        if (data.available_devices.length > 0) {
          if(isEditCamera){
            const newDevicesList = data.available_devices.map((item: IVideo) => ({ name: item.description, value: item.name }));
            newDevicesList.unshift({'name': deviceObj.description, 'value': deviceObj.name});
            setVideoDeviceList(newDevicesList);
          } else {
            setVideoDeviceList(data.available_devices.map((item: IVideo) => ({ name: item.description, value: item.name })));
          }
        } else {
          if(!isEditCamera){
            setVideoDeviceList([{ name: tRef.current('Device not available'), value: '' }]);
            notificationRef.current({ type: 'neutral', message: tRef.current('Video device not available') });
          }
        }
      } else {
        if(!isEditCamera){
          setVideoDeviceList([{ name: tRef.current('Device not available'), value: '' }]);
        }
        notificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') });
      }
    } catch (error) {
      if(!isEditCamera){
        setVideoDeviceList([{ name: tRef.current('Device not available'), value: '' }]);
      }
      notificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') });
      console.error(error);
    }
    setLoader(false);
  }, [isEditCamera]);

  const fetchCameraDetails = useCallback(async (cameraName) => {
    setEditLoading(true);
    try {
      const { data: { data, status_code } } = await getSingleCamera(cameraName);
      const {
        stream_name = '',
        periodic_snapshot_interval = 1,
        camera_name = '',
        camera_type = '',
        notes = '',
        camera_enabled = false,
        video_configuration
      } = data;
      setPrevValues(data);
      if (status_code === 0) {
        const dataValue = { camera_name: '', camera_type: '', notes: '', camera_enabled: false, };
        dataValue.camera_name = camera_name;
        dataValue.camera_enabled = camera_enabled;
        dataValue.camera_type = camera_type;
        dataValue.notes = notes;
        if (notes.length > 0) {
          setShowNotes(true);
        }
        setStreamName(stream_name);
        setPeriodicSnapshotCameraGet(periodic_snapshot_interval ?? 60);
        setBasicConfig(dataValue);

        if (camera_type === 'RTSP') {
          if (video_configuration) {
            const { password, short_header, buffer_time, rtsp_url, username, packet_delay_type, low_latency_decode, optional_cam_meta } = video_configuration;
            const { camera_manufacture, camera_model, camera_web_ui_url } = optional_cam_meta;
            setIpCamera(prev => ({...prev, password, camera_model, short_header, camera_manufacture, buffer_time, rtsp_url, username, camera_web_ui_url, packet_delay_type, low_latency_decode}));
          }
        } else if (camera_type === 'USB') {
          if (video_configuration) {
            setVideoDeviceList(prev => [...prev, { value: video_configuration.video_device, name: video_configuration.video_manufacturer }]);
            const deviceObj = { name: video_configuration.video_device, description: video_configuration.video_manufacturer };
            setUsbDevice(deviceObj);
            setSourceType(video_configuration?.video_source_type);
            fetchVideoDevice(deviceObj);
          }
        }
        else if (camera_type === 'RTMP') {
          if (video_configuration) {
            const { rtmp_source_url, rtmp_server_type, rtmp_stream_key, rtmp_url_type } = video_configuration;
            setServerUrlTypeRTMP(rtmp_url_type);
            setRtmpCamera(prev => ({ ...prev, rtmp_source_url: rtmp_source_url, rtmp_server_type: rtmp_server_type, rtmp_stream_key: rtmp_stream_key }));
          }
        }
        else if (camera_type === 'SAFIE') {
          if (video_configuration) {
            setSafieDeviceName(video_configuration.video_device);
            setSafieDeviceNameForComparision(video_configuration.video_device);
          }
        }
        else if (camera_type === 'HTTP') {
          if (video_configuration) {
            setHlsURL(video_configuration.url);
          }
        }
        setEditLoading(false);
      } else if (status_code === 403) {
        notificationRef.current({ type: 'error', message: tRef.current('Authorization required to access') });
        if (await AuthService.logoutUser() !== 200) {
          notificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') });
        }
      }
      else {
        setEditLoading(false);
        notificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') });
      }
    } catch (error) {
      setEditLoading(false);
      notificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') });
      console.error((error as Error).message);
    }
  }, [fetchVideoDevice]);

  const onSelectTab = useCallback((tabName: string) => {
    setSelectedTab(tabName);
    history.replace(window.location.pathname + '?camera-name='+ streamName + '?selectedTab=' + tabName);
  }, [history, streamName]);

  useEffect(() => {
    const query = new URLSearchParams(search);
    if (isEditCamera) {
      if (query.get('camera-name')) {
        fetchCameraDetails(query.get('camera-name'));
      }
    } else {
      setEditLoading(false);
    }
  }, [isEditCamera, search, fetchCameraDetails]);

  const addCameraData = useCallback(async (basic, video_device, source) => {
    if (basic.camera_type === 'USB' && video_device.name === '') {
      notificationRef.current({ type: 'error', message: t('Please select video device') });
      return;
    }
    let data = {};
    if (basic.camera_type === 'HTTP') {
      // eslint-disable-next-line
      const urlRegEx = /^(http|https):\/\//;
      if (hlsUrl === '') {
        notificationRef.current({ type: 'error', message: t('Please enter URL') });
        return;
      }
      if (!urlRegEx.test(hlsUrl)) {
        notificationRef.current({ type: 'error', message: t('Invalid URL') });
        return;
      }
      data = {
        ...basic, notes: basic.notes.trim(), camera_name: basic.camera_name.trim(),
        video_configuration: {
          ...({http_source_url: hlsUrl})
        }
      };
    } else {
      data = { ...basic, notes: basic.notes.trim(), camera_name: basic.camera_name.trim(), video_configuration: { video_device,  video_source_type: source } };
    }
    try {
      if (isEditCamera) {
        setEditButtonLoading(true);
        const { data: { status_code } } = await updateCamera({ ...data, stream_name: streamName });
        if (status_code === 201) {
          notificationRef.current({ type: 'success', message: t('Camera details updated successfully') });
          push(`/cameras/camera-details/${streamName}/overview`); 
        } else if (status_code === 403) {
          notificationRef.current({ type: 'error', message: t('Authorization required to access') });
          if (await AuthService.logoutUser() !== 200) {
            notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
          }
        }
        else {
          notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
        }
      } else {
        setEditButtonLoading(true);
        const { data: { status_code } } = await addCamera(data);
        if (status_code === 201) {
          notificationRef.current({ type: 'success', message: t('Camera added successfully') });
          setEditButtonLoading(false);
          push('/cameras');
        } else if (status_code === 403) {
          notificationRef.current({ type: 'error', message: t('Authorization required to access') });
          if (await AuthService.logoutUser() !== 200) {
            notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
          }
        }
        else {
          notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
        }
      }
    } catch (error) {
      console.error((error as Error).message);
      notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
    }
    setEditButtonLoading(false);
  }, [streamName, hlsUrl, isEditCamera, push, t]);

  const getcameraStatus = useCallback((selectedValue) => {
    Object.keys(showCameraError).map((item) => {
      const numIndex = Number(item);
      if(showCameraError[numIndex].value === selectedValue ) {
        if(showCameraError[numIndex].video_streaming) {
          setShowCameraErrorBoolean(true);
        } else {
          setShowCameraErrorBoolean(false);
        }
      }
      return item;
    });
  },[showCameraError]);

  useEffect(() => {
    if(showCameraError ) {
      getcameraStatus(safieDeviceName);
    }
  },[showCameraError, safieDeviceName, getcameraStatus]);

  const getErrorDetails = useCallback((): ReactElement | null => {
    if (showCameraErrorBoolean || showCameraErrorBoolean === undefined) {
      return null;
    } else {
      if(basicConfig.camera_type === 'SAFIE'){
        return (
          <ErrorBox bgColor='rgb(239, 120, 120)'>
            <Icon size={24} icon='error' color='inverse' />
            <ErrorContainer>
              <ErrorDescription>{t('Livefeed cannot be fetched for this camera. The device may be offline.')}</ErrorDescription>
            </ErrorContainer>
          </ErrorBox>
        );
      }
    }
    return null;
  }, [showCameraErrorBoolean, t, basicConfig.camera_type]);

  const onClickCameraAddEdit = useCallback(() => {
    if (postRef !== null && (basicConfig.camera_type === 'RTSP' || basicConfig.camera_type === 'RTMP')) {
      postRef?.current?.postData();
    } else if (basicConfig.camera_type === 'USB') {
      addCameraData(basicConfig, usbDevice, sourceType);
    } else if (basicConfig.camera_type === 'HTTP') {
      addCameraData(basicConfig, hlsUrl, sourceType);
    } else {
      addCameraData(basicConfig, safieDeviceName, sourceType);
    }
  }, [basicConfig, addCameraData, usbDevice, safieDeviceName, hlsUrl, sourceType]);

  const onChangeHandler = useCallback(({ target: { name, value } }) => {
    if (name === 'notes') {
      if (value.trim().length <= 1024) {
        setBasicConfig(prev => ({ ...prev, notes: value }));
      }
    } else {
      setBasicConfig(prev => ({ ...prev, [name]: value }));
    }
  }, []);

  const fetchSafieDevices = useCallback(async () => {
    try {
      const {data:{status_code, data}} = await getSafieDevice();
      if (status_code === '10000') {
        if(data === null){
          setShowNoDeviceSafie(true);
          setSafieCameraList([{value: '', name: t('Device not available')}]);
          notificationRef.current({ type: 'neutral', message: t('No Safie device associated with this account') });
          setSafieLoader(false);
          return;
        }
        if(data.lenght === 0){
          setShowNoDeviceSafie(true);
        }
        if (data.length > 0) {
          setShowCameraError(data.map((item: ISafieDeviceMap) => {
            return { name: `${item.setting.name} (${item.device_id})`, value: item.device_id , video_streaming: item.status.video_streaming};
          }));
          setSafieCameraList(data.map((item: ISafieDeviceMap) => {
            return { name: `${item.setting.name} (${item.device_id})`, value: item.device_id };
          }));
        } else {
          setSafieCameraList([{value: '', name: t('Device not available')}]);
          notificationRef.current({ type: 'neutral', message: t('Safie device not available') });
        }
      } else if (status_code === '10607') {
        setSafieCameraList([{value: '', name: t('Device not available')}]);
        notificationRef.current({ type: 'error', message: t('No Safie device associated with this account') });
      } else {
        setSafieCameraList([{value: '', name: t('Device not available')}]);
        notificationRef.current({ type: 'error', message: t('Failed to communicate with the Safie Cloud') });
      }
    } catch (e) {
      setSafieCameraList([{value: '', name: t('Device not available')}]);
      notificationRef.current({ type: 'error', message: t('Failed to communicate with the Safie Cloud') });
      console.error(e);
    }
    setSafieLoader(false);
  }, [t]);

  const getSafieConfigDetails = useCallback(async() => {
    try{
      const { data: { data, status_code } }  = await getSafieConfig();
      if(status_code==='10000') {
        if(data?.action === 'DeleteStack') {
          setSafieConfiguration(false);
          setSafieLoader(false);
        } else {
          if(data?.services?.safie_settings1?.enabled === true ) {
            setSafieConfiguration(true);
            fetchSafieDevices();  
          } else {
            setSafieConfiguration(false);
            setSafieLoader(false);
          }
        }
      }else if(status_code==='10400'){
        setSafieLoader(false);
        setSafieConfiguration(false);
      } else {
        setSafieConfiguration(false);
        notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
      }
    }catch (error){
      if(((error as Error).message).endsWith('404')){
        setSafieLoader(false);   //show configuration
        setSafieConfiguration(false);
        return;
      }
      setSafieConfiguration(false);
      notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
      console.error((error as Error).message);
    }
  }, [t, fetchSafieDevices]);

  useEffect(() => {
    if (basicConfig.camera_type === 'USB' && !isEditCamera) {
      fetchVideoDevice(undefined);
    } else if (basicConfig.camera_type === 'SAFIE') {
      if(safieCameraList.length === 0){
        setSafieLoader(true);
      }
      getSafieConfigDetails();
    }
  }, [fetchVideoDevice, fetchSafieDevices, basicConfig.camera_type, getSafieConfigDetails, isEditCamera, safieCameraList.length]);

  const getTrafficDetails = useCallback(async () => {
    try {
      const res = await getConfigDetails(streamName);
      if (res.data.status_code === 0) {
        setCameraUrl(res.data.data.camera_uri);
      } else {
        sendNotificationRef.current({
          type: 'error',
          message: tRef.current('Failed to fetch settings')
        });
      }
    } catch (error) {
      sendNotificationRef.current({
        type: 'error',
        message: tRef.current('Failed to fetch settings')
      });
      console.error(error);
    }
  }, [setCameraUrl, streamName]);
  
  useEffect(() => {
    if(isEditCamera && streamName){
      getTrafficDetails();
    }
  }, [getTrafficDetails, isEditCamera, streamName]);

  const onDelete = useCallback(async () => {
    try {
      const { data: { status_code } } = await deleteSingleCamera(streamName);
      if (status_code === 0) {
        notificationRef.current({ type: 'success', message: t('Camera deleted successfully') });
        push('/cameras');
        setModalOpen(false);
      } else if (status_code === 403) {
        notificationRef.current({ type: 'error', message: t('Authorization required to access') });
        if (await AuthService.logoutUser() !== 200) {
          notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
        }
      }
      else {
        notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
      }
    } catch (error) {
      notificationRef.current({ type: 'error', message: t('Failed to communicate with the system') });
      console.error((error as Error).message);
    }
  }, [setModalOpen, push, streamName, t]);

  const onClickDelete = useCallback(() => {
    createModal({
      isCloseEnable: true,
      closeText: t('CLOSE'),
      width: '480px',
      padding: true,
      customComponent: (
        <ModelBox titleText='Delete Camera?' onConfirm={() => { onDelete(); }} modalOpen={setModalOpen}>
          <ModelTextConfirm htmlFor='' labelText={t('Please confirm that you wish to delete the following camera')} />
          <ModelText title={(basicConfig.camera_name)} htmlFor='' labelText={t(basicConfig.camera_name)} />
          <DelModalLine />
          <ModelTextConfirm htmlFor='' labelText={t('Please Note') + ':'} />
          <ModelTextConfirm htmlFor='' labelText={t('• Once confirmed this action cannot be undone.')} />
          <DelModalLine />
        </ModelBox>),
    });
  }, [basicConfig.camera_name, onDelete, createModal, setModalOpen, t]);

  const onClickCancel = useCallback(() => {
    if(isEditCamera){
      createModal({
        isCloseEnable: false,
        width: '480px',
        padding: true,
        dismissCallback: () => { setModalOpen(false); },
        customComponent: (
          <ModelBox onConfirm={() => { setModalOpen(false); goBack(); }} modalOpen={setModalOpen} cancelText='No' confirmText='Yes' confirmStyle='primary'>
            <ModelText htmlFor='' labelText={t('Are you sure you want to cancel the changes?')} />
          </ModelBox>),
      });
    }
    const data = {...basicConfig};
    const dataCompare = {...basicConfigDataTemp};
    if(JSON.stringify(data) === JSON.stringify(dataCompare)){
      goBack();
      return;
    } 
    else{
      createModal({
        isCloseEnable: false,
        width: '480px',
        padding: true,
        dismissCallback: () => { setModalOpen(false); },
        customComponent: (
          <ModelBox onConfirm={() => { setModalOpen(false); goBack(); }} modalOpen={setModalOpen} cancelText='No' confirmText='Yes' confirmStyle='primary'>
            <ModelText htmlFor='' labelText={t('Are you sure you want to cancel the changes?')} />
          </ModelBox>),
      });
    }
  }, [goBack, createModal, setModalOpen, t, basicConfig, isEditCamera]);

  const onSelectUSBDevice = useCallback((event) => {
    setUsbDevice({ name: event, description: videoDeviceList.filter(item => item.value === event)[0].name });
  }, [videoDeviceList]);

  const onSourceDevice = useCallback((event) => {
    setSourceType(event);
  }, []);

  const onSelectSafieCamera = (event: string) => {
    if(isEditCamera) {
      if(safieDeviceNameForComparision === event){
        setSafieDeviceName(safieDeviceNameForComparision);
        return;
      }
      if(safieCameraUsedList?.includes(event)) {
        notificationRef.current({ type: 'error', message: t('Safie stream for selected camera name already exists')});
        setSafieDeviceName('');
        setShowCameraErrorBoolean(true); 
      }else {
        if(safieCameraUsedList?.includes(event)) {
          setSafieDeviceName('');
          notificationRef.current({ type: 'error', message: t('Safie stream for selected camera name already exists') });
          setShowCameraErrorBoolean(true); 
        } else {
          setSafieDeviceName(event as string);
        }
      }
    } else {
      if(safieCameraUsedList?.includes(event)) {
        setSafieDeviceName('');
        notificationRef.current({ type: 'error', message: t('Safie stream for selected camera name already exists') });
        setShowCameraErrorBoolean(true); 
      } else {
        setSafieDeviceName(event as string);
      }
    }
  };

  useEffect(() => {
    try {      
      if (prevValues && compareNewValues) {
        const prevVal = prevValues;
        delete prevVal.algorithm_configs;
        delete prevVal.enabled_analysis;
        if(prevValues.camera_type === 'USB'){
          if(prevVal?.video_configuration){
            delete prevVal.usb_device_status;
            delete prevVal.latest_activity_time;
            delete prevVal.stream_name;
            prevVal.name = prevVal.video_configuration.video_device;
            prevVal.description = prevVal.video_configuration.video_manufacturer;
            prevVal.safieDeviceName = '';
            delete prevVal.video_configuration;
            delete prevVal.periodic_snapshot_interval;
          }
        } else if(prevValues.camera_type === 'SAFIE'){
          if(prevVal?.video_configuration){
            delete prevVal.usb_device_status;
            delete prevVal.latest_activity_time;
            delete prevVal.stream_name;
            prevVal.name = '';
            prevVal.description = '';
            prevVal.safieDeviceName = prevVal.video_configuration.video_device;
            delete prevVal.video_configuration;
            delete prevVal.periodic_snapshot_interval;
          }
        } else if(prevValues.camera_type === 'RTMP'){
          if(prevVal?.video_configuration){
            delete prevVal.stream_name;
            delete prevVal.latest_activity_time;
            prevVal.video_configuration.rtmp_server_type = prevVal.video_configuration.server_type;
            prevVal.video_configuration.rtmp_source_url = prevVal.video_configuration.server_url;
            prevVal.video_configuration.rtmp_stream_key = prevVal.video_configuration.server_key;
            if(prevVal.video_configuration.server_url_type === '1' ){
              prevVal.video_configuration.rtmp_url_type = true;
            }else {
              prevVal.video_configuration.rtmp_url_type = false;
            } 
            delete prevVal.video_configuration.server_type;
            delete prevVal.video_configuration.server_url;
            delete prevVal.video_configuration.server_key;
            delete prevVal.video_configuration.stream_name;
            delete prevVal.video_configuration.server_url_type;
            delete prevVal.periodic_snapshot_interval;
          }
        } else if(prevValues.camera_type === 'RTSP'){
          if(prevVal?.video_configuration && !prevVal?.video_configuration?.optional_cam_meta){
            delete prevVal.stream_name;
            delete prevVal.latest_activity_time;
            prevVal.video_configuration.optional_cam_meta = {'camera_manufacture': prevVal.video_configuration.camera_manufacture};
            prevVal.video_configuration.optional_cam_meta.camera_model = prevVal.video_configuration.camera_model;
            prevVal.video_configuration.optional_cam_meta.camera_web_ui_url = prevVal.video_configuration.camera_web_ui_url;
            delete prevVal.video_configuration.camera_manufacture;
            delete prevVal.video_configuration.camera_model;
            delete prevVal.video_configuration.camera_web_ui_url;
            delete prevVal.video_configuration.stream_name;
            if(typeof(compareNewValues?.video_configuration?.buffer_time)=== 'string'){
              prevVal.video_configuration.buffer_time = prevVal.video_configuration.buffer_time.toString();
            }
            delete prevVal.periodic_snapshot_interval;
          }
        }
        prevCompareVal.current = prevVal;
      }
    } catch (error) {
      console.error(error);
    }
  }, [prevValues, compareNewValues]);

  const goToPreviousPage = useCallback(() => {
    if(isEqual(prevCompareVal.current, compareNewValues)){
      push('/cameras/camera-details/' + streamName + '/overview');
      return;
    } else {
      onClickCancel();
    }
  }, [compareNewValues, streamName, push, onClickCancel]);

  const onHlsUrlChange = useCallback((url) => {
    setHlsURL(url);
  }, []);

  return (
    <MainContainer>
      <Container>
        <HeaderContainer>
          <PageHeaderComponent>
            <AreaContainer title={isEditCamera ? basicConfig.camera_name : t('Cameras')} onClick={goToPreviousPage}>{isEditCamera ? basicConfig.camera_name : t('Cameras')}</AreaContainer>
            <PageHeader
              icon='DevicesScorerCamera'
              introductionText=''
              title={isEditCamera ? t('Edit Camera') : t('Add Camera')}
              updateDocTitle={false}
            />
          </PageHeaderComponent>
          <div>
            {selectedTab === 'basic' ?
              <HeaderButton>
                <Button design='secondary' disabled={editLoading} onClick={goToPreviousPage}>{t('Cancel')}</Button>
                <ButtonWithLoading onClick={onClickCameraAddEdit} disabled={basicConfig.camera_type === '' || basicConfig?.camera_name?.trim() === '' || (basicConfig.camera_type === 'SAFIE' && safieDeviceName === '')} loading={editButtonLoading}>{isEditCamera ? t('Save') : t('Add Camera')}</ButtonWithLoading>
              </HeaderButton>
              :null}
          </div>
        </HeaderContainer>
        <Tabs>
          <TabList defaultTabId={selectedTab}>
            <Tab tabFor='basic' onClick={() => { onSelectTab('basic'); }}><div>{t('Basic')}</div></Tab>
            {isEditCamera ?
              <>
                <Tab tabFor='advanced'><div onClick={() => { onSelectTab('advanced'); }}>{t('Advanced')}</div></Tab>
                {(cameraUrl !== '' && cameraUrl !== null) &&
                  <Tab tabFor='camera_web_ui' onClick={onCameraWebUI}><div>{t('Camera Web UI')}</div></Tab>}
              </>: ''}
          </TabList>
          <StyledDivider />
          <TabContent tabId='basic'>
            {!editLoading ?
              <>
                <BaseConfig>
                  <TextBasicBox>
                    <TextBasicConfig htmlFor='' labelText={t('Basic Configuration')} />
                    <LineBasic />
                    <SwitchBox maxWidth={i18n.language === 'ja' ? '126px' : '163px'}>
                      <Switch
                        checked={basicConfig.camera_enabled}
                        leftTheme='off'
                        onChangeCallback={() => setBasicConfig(prev => ({ ...prev, camera_enabled: !prev.camera_enabled }))}
                        rightTheme='on'
                        state='default'
                      />
                      <SwitchText htmlFor='' labelText={`${basicConfig.camera_enabled ? t('Camera Enabled') : t('Camera Disabled')}`} />
                    </SwitchBox>
                  </TextBasicBox>

                  <InputFieldContainer>
                    <InputFieldBox>
                      <TextFieldRequired>
                        <TextField
                          tabIndex={1}
                          name='camera_name'
                          label={t('Camera Name')}
                          fieldState='default'
                          onChange={onChangeHandler}
                          value={basicConfig.camera_name}
                          maxLength={255}
                        />
                      </TextFieldRequired>
                    </InputFieldBox>
                    <InputFieldBox>
                      <TextFieldRequired>
                        <SelectField
                          tabIndex={2}
                          changeCallback={(event: string | number) => setBasicConfig(prev => ({ ...prev, camera_type: event as string }))}
                          defaultValue={basicConfig.camera_type}
                          disabled={isEditCamera}
                          label={{
                            htmlFor: 'Camera Type',
                            text: t('Camera Type')
                          }}
                          placeholder={t('Select Camera Type...')}
                        >
                          {CAMERA_TYPE.map(item => (<option key={item.name} value={item.value}>{t(item.name)}</option>))}
                        </SelectField>
                      </TextFieldRequired>
                    </InputFieldBox>
                  </InputFieldContainer>
                  <NotesBox showNotes={showNotes}>
                    {showNotes ?
                      <TextAreaField tabIndex={3} maxLength={1024} value={basicConfig.notes} fieldState='default' label={t('Notes')} name='notes' onChange={onChangeHandler} />
                      :
                      <ButtonWithIcon
                        design='primary'
                        icon='Add'
                        onClick={() => { setShowNotes(true); }}
                        position='left'
                        size='small'
                      >
                        {t('Add Notes')}
                      </ButtonWithIcon>}
                  </NotesBox>
                </BaseConfig>
                <VideoConfigBox>
                  <BaseConfig>
                    <TextConfigBox>
                      <TextConfig htmlFor='' labelText={t('Video Source')} />
                      <Line />
                    </TextConfigBox>
                    {basicConfig.camera_type === 'USB' && loader &&
                      <SpinnerBoxWebCam>
                        <Spinner size='large' styling='primary' />
                        <Label htmlFor='loader' labelText={t('Loading') + '...'} />
                      </SpinnerBoxWebCam>}
                    <WebFieldContainer>
                      {(basicConfig.camera_type === '' || basicConfig.camera_type === 'USB') && !loader &&
                        <Formatter>
                          <FormatterBox>
                            <TextFieldRequired>
                              <InputBox blur={basicConfig.camera_type === ''}>
                                <SelectField
                                  tabIndex={4}
                                  changeCallback={onSelectUSBDevice}
                                  defaultValue={usbDevice?.name}
                                  value={usbDevice?.name}
                                  label={{
                                    htmlFor: 'Video Device',
                                    text: t('Video Device')
                                  }}
                                  placeholder={basicConfig.camera_type === '' ? t('Select Camera Type to continue…') : (showNoDevice ? t('Device not available')  : t('Select from connected devices…'))}
                                  disabled={basicConfig.camera_type !== 'USB'}
                                >
                                  {videoDeviceList.map(item => (<option key={item.name} value={item.value}>{item.value !== '' ? `${item.value} (${item.name})`: `${item.name}`}</option>))}
                                </SelectField>
                              </InputBox>
                            </TextFieldRequired>
                          </FormatterBox>

                          {(basicConfig.camera_type === 'USB') ?
                            <FormatterBox>
                              <TextFieldRequiredSource>
                                <InputBox blur={false}>
                                  <SelectField
                                    tabIndex={5}
                                    changeCallback={onSourceDevice}
                                    defaultValue={sourceType}
                                    value={sourceType}
                                    label={{
                                      htmlFor: 'Video Data Format',
                                      text: t('Video Data Format')
                                    }}
                                    placeholder={t('Select Source Type to continue…')}
                                  >
                                    {sourceTypeList.map((item, index) => (<option key={item} value={item}>{`${sourceTypeDisplayList[index]}`}</option>))}
                                  </SelectField>
                                </InputBox>
                              </TextFieldRequiredSource>
                            </FormatterBox>
                            : null}
                        </Formatter>} 
                    </WebFieldContainer>
                    {basicConfig.camera_type === 'SAFIE' &&
                      <>
                        {(!safieConfiguration || safieConfiguration === undefined) && !safieLoader &&
                          <SafieBox>
                            <SafieText htmlFor='' labelText={t('Safie integration is not yet configured')} />
                            <SafieIntro htmlFor='' labelText={t('This feature requires Safie authorisation to use. Once configured you will be able to select your\nSafie camera from a list here.')} />
                            <Button onClick={() => { push('/settings/safie-settings');}}>{t('Begin Setup')}</Button>
                          </SafieBox>}
                      </>}
                          
                    {basicConfig.camera_type === 'SAFIE' &&
                      <>
                        {(safieConfiguration) &&
                          <>
                            {(safieCameraList.length > 0) &&
                              <>
                                <WebFieldContainerSafie>
                                  <InputBox>
                                    <SelectField
                                      tabIndex={4}
                                      changeCallback={(event: string) => onSelectSafieCamera(event as string)}
                                      defaultValue={safieDeviceName}
                                      value={safieDeviceName}
                                      label={{
                                        htmlFor: 'Safie Camera',
                                        text: t('Safie Camera')
                                      }}
                                      placeholder={showNoDeviceSafie ? t('Device not available') : t('Select your camera…')}
                                    >
                                      {safieCameraList.map(item => (<option key={item.name} value={item.value}>{item.name}</option>))}
                                    </SelectField>
                                  </InputBox>
                                </WebFieldContainerSafie>
                              </>}
                            {(safieLoader) &&
                              <SafieSpinnerBox>
                                <Spinner size='large' styling='primary' />
                                <Label htmlFor='loader' labelText={t('Loading') + '...'} />
                              </SafieSpinnerBox>}
                          </>}
                      </>}
                    {getErrorDetails()}
                    {basicConfig.camera_type === 'RTSP' && <IPCamera isEditCamera={isEditCamera} streamName={streamName} basicConfig={basicConfig} ipCamera={ipCamera} setIpCamera={setIpCamera} ref={postRef} setEditButtonLoading={setEditButtonLoading} setCompareNewValues={setCompareNewValues} />}
                    {basicConfig.camera_type === 'RTMP' && <RTMPCamera isEditCamera={isEditCamera} streamName={streamName} basicConfig={basicConfig} rtmpCamera={rtmpCamera} setRtmpCamera={setRtmpCamera} ref={postRef} setEditButtonLoading={setEditButtonLoading} serverUrlTypeRTMP={serverUrlTypeRTMP} setCompareNewValues={setCompareNewValues} />}
                    {basicConfig.camera_type === 'HTTP' && <HLSCamera hlsUrl={hlsUrl} onHlsUrlChange={onHlsUrlChange} />}
                  </BaseConfig>
                </VideoConfigBox>
              </>:
              <SpinnerBox>
                <Spinner size='large' styling='primary' />
                <Label htmlFor='loader' labelText={t('Loading') + '...'} />
              </SpinnerBox>}

          </TabContent> 
          <TabContent tabId='advanced'>
            {isEditCamera &&
              <>
                <AdvancedDetailsBox>
                  <AdvancedContainer>
                    <AdvanceTab setCameraUrl={getsetCameraUrl} cameraUrl={cameraUrl} streamName={streamName} isEditCamera={isEditCamera} periodicSnapshotCameraGet={periodicSnapshotCameraGet} />
                  </AdvancedContainer>
                </AdvancedDetailsBox>
              </>}
            {isEditCamera &&
              <AdvancedBox>
                <AdvanceTextBox>
                  <AdvancedTitle htmlFor='' labelText={t('Delete Camera')} />
                  <AdvancedTitleSubtitle htmlFor='' labelText={t('Delete this camera from the system. This action cannot be undone and to restore access you will need to recreate the camera. No other data will be removed.')} />
                </AdvanceTextBox>
                <ButtonFormatter lang={language}>
                  <DeleteButton design='danger' onClick={onClickDelete}>{t('Delete Camera')}</DeleteButton> 
                </ButtonFormatter>
              </AdvancedBox>}
          </TabContent> 
          <TabContent tabId='camera_web_ui'>
            <AdvanceSectionCameraWebUI lang={language}>
              <AdvanceTextBox>
                <AdvancedSubtitle htmlFor='' labelText={t('This will launch the camera’s own web based configuration page in a new tab or window. These settings should only be adjusted by qualified persons.')} />
              </AdvanceTextBox>
            </AdvanceSectionCameraWebUI>
          </TabContent>
        </Tabs>  
      </Container>
    </MainContainer>
  );
};

export default AddCamera;
