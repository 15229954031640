import React from 'react';
import ReactDom from 'react-dom';
import styled, { css, keyframes } from 'styled-components';
import {Icon} from 'scorer-ui-kit';

const initAnimation = keyframes`
  0% {
    transform: translate(-50%, -100%);
  }
  100% {
    transform: translate(-50%, 0%);
  }
`;

const Container = styled.div<{type: AlertType}>`
  min-height: 50px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 14px;
  width: 900px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  font-family: ${({ theme }) => theme.fontFamily.ui };
  ${({type, theme}) => theme.styles.feedbackBar[type] };
  ${({theme}) => theme.typography.feedbackBar.message };
  ${({theme}) => css`
    animation: ${initAnimation} ${theme.animation.speed.slow} ${theme.animation.easing.primary.easeInOut};
    `
};
`;

type AlertType = 'error'|'warning'|'info'|'success'|'neutral';

const MainMessage = styled.div`
  padding: 12px 6px 12px 12px;
  line-height: 20px;
  text-align: left;
  flex-grow: 2;
`;

export type INotificationProps = {
  type: AlertType
  message: any
}

const DiskFullNotification : React.FC<INotificationProps> = ({ type ='info', message}) => {

  return( (message)
    ? ReactDom.createPortal(
      <Container type={type}>
        <Icon icon='Critical' color='inverse' />
        <MainMessage>{message}</MainMessage>
      </Container>
      , document.body)
    : null
  );
};

export default DiskFullNotification;