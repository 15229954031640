import React, { useState, useEffect, useCallback, FC, useRef} from 'react';
import {
  Route,
  Redirect
} from 'react-router-dom';
import {useHistory, useLocation} from 'react-router';
import SecureRoute from 'SecureRoute';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { MainMenu, Layout, TopBar, Spinner, Label, useNotification } from 'scorer-ui-kit';
import { IMenuItemSubmenu, IMenuItemTop, IMenuTop } from 'scorer-ui-kit/dist/Global';
import styled from 'styled-components';


import logoMarkSvg from './svg/logo-mark.svg';
import LogoTextAppNameEn from './svg/logo-app-name_en.svg';
import menu from './pages/menu.json';

import Login from 'pages/Login';
import TokenService from 'services/tokenService';
import AddCamera from 'pages/AddCamera';
import SafieIntegration from 'pages/SafieIntegration';
import CloudUploadSettings from 'pages/CloudUploadSettings';
import { SUPERSET_URL, SUPERSET_URL_DASHBOARD_1, SUPERSET_URL_DASHBOARD_2, SUPERSET_URL_DASHBOARD_3, validRoutes } from './constants';
import { getGuestUser, getHealthStatusDetails, getEdgeDeviceDetails, getAllUsers, getBugherdDetails, getSafieConfigDetails } from './services/apiConfig';
import CameraDetails from 'pages/CameraDetails';
import Export from 'pages/Export';
import Cameras from 'pages/Cameras';
import CameraConfiguration from 'pages/CameraConfiguration';
import UserManagement from 'pages/User';
import CreateEditUser from 'pages/User/CreateEditUser';
import clonedeep from 'lodash.clonedeep';
import PasswordModal from 'components/PasswordModal';
import DiskFullNotification from './components/DiskFullNotification';
import Role from 'components/Role';
import FrequentLane from 'pages/CameraConfiguration/FrequentLane';
import TrafficCounter from 'pages/CameraConfiguration/TrafficCounter';
import ReverseRunDetection from 'pages/CameraConfiguration/ReverseRunDetection';
import ExclusionZones from 'pages/CameraConfiguration/ExclusionZones';
import CongestionStopDetection from 'pages/CameraConfiguration/CongestionStopDetection';
import LowSpeedDetection from 'pages/CameraConfiguration/LowSpeedDetection';
import AdvancedLowSpeed from 'pages/CameraConfiguration/AdvancedLowSpeed';
import AlertSuppression from 'pages/CameraConfiguration/Schedules/AlertSuppression';
import AlertConfiguration from 'pages/AlertConfiguration';
import AuthService from 'services/authService';
import { INotificationItem } from 'interface';
import AllAlerts from 'pages/AllAlerts';
import NotificationsHistory from 'components/NotificationHistory';
import HitPoint from 'pages/CameraConfiguration/HitPoint';

const MainContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const MainMenuContainer = styled(MainMenu)`
`;

const SpinnerBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 89vh;
  align-items: center;
  justify-content: center;
`;

const MessageDiv=styled.div<{marginTop?:string,display:boolean}>`
  margin-top:${({ marginTop })=>marginTop};
  display:${({display})=>display?'block':'none'};
`;

interface IEdgeDevice {
  device_id:string,
  version: string,
}

interface IStatusContext {
  activeAlertTimeDrawer: number;
  setActiveAlertTimePages: React.Dispatch<React.SetStateAction<number>>;
  activeAlertTimePages?: number;
  alertActiveCount?:number;
  alertData?: INotificationItem[];
}

export const StatusContext = React.createContext<IStatusContext>({
  alertData: [],
  activeAlertTimeDrawer: 0,
  setActiveAlertTimePages: ()=>{},
  activeAlertTimePages: 0,
  alertActiveCount:0,
});

const EDEGE_DEVICE_DATA = {
  device_id:'',
  version: '',
};

const App: FC = () => {

  const { t } = useTranslation(['CommonDict']);
  const [menus, setMenus] = useState<IMenuTop>({ items: [] });
  const [userType, setUserType] = useState(false);
  const [isDiskFull, setIsDiskFull] = useState<boolean>(false);
  const [isSafieStatusBad,setSafieStatusBad]=useState<boolean>(false);
  const [guestUser, setGuestUser] = useState<boolean>(false);
  const [loadingGuest, setLoadingGuest] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState(!(TokenService?.getUser()) ? false : true);
  const location = useLocation();
  const history = useHistory();
  const userName = TokenService.getUser();
  const { sendNotification } = useNotification();
  const notificationRef = useRef(sendNotification);
  const tRef = useRef(t);
  const [boundingBoxes, setBoundingBoxes] = useState(typeof(localStorage.getItem(`checkoBox_Video_Annotation_${userName}`) as string) === 'string' ? JSON.parse(localStorage.getItem(`checkoBox_Video_Annotation_${userName}`) as string) : false );
  const [edgeDeviceData, setEdgeDeviceData] = useState<IEdgeDevice>(EDEGE_DEVICE_DATA);
  const [activeAlertTimeDrawer, setActiveAlertTimeDrawer] = useState<number>(0);
  const [activeAlertTimePages, setActiveAlertTimePages] = useState<number>(0);
  const [alertActiveCount, setAlertActiveCOunt]=useState(0);
  const [count, setCount] = useState<number>(0);
  const [alertData, setAlertData] = useState<INotificationItem[]>([]);
  const deviceText = i18n.language === 'ja' ? 'デバイスID' : 'Device ID';
  const versionInfo = {
    icon: 'Information',
    title: edgeDeviceData.version ? edgeDeviceData.version : '-'};
  const deviceInfo = [
    {
      icon: 'Information',
      title: deviceText,
      subTitle: edgeDeviceData.device_id ? edgeDeviceData.device_id : '-',
      notes:'',
      hasCopyIcon:true
    }
  ];

  // Bugherd side-bar
  const configureBugherdSideBar = useCallback(async () => {
    try {
      const {data} = await getBugherdDetails();
      if (data.data.bugherd_enabled === true) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://www.bugherd.com/sidebarv2.js?apikey=f0jfkq3yvu8scwzgnixrua';
        script.async = true;
        document.head.appendChild(script);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    configureBugherdSideBar();
  }, [configureBugherdSideBar]);

  const fetchCameraDetails = useCallback(async () => {
    try {
      const  { data, status }  = await getEdgeDeviceDetails();
      if(status === 200){
        setEdgeDeviceData({device_id: data.message['device-id'], version:data.message['software-version']});
      }
    } catch (error) {
      console.error((error as Error).message);
    }
  }, []);

  useEffect(()=>{
    fetchCameraDetails();
  }, [fetchCameraDetails]);

  const fetchGuestData = useCallback(async () => {
    setLoadingGuest(true);
    try {
      const { data: { data, status_code } } = await getGuestUser();
      if (status_code === 200) {
        setGuestUser(data.guest_enabled);
      } else {
        setGuestUser(false);
      }
    } catch (error) {
      setGuestUser(false);
      console.error((error as Error).message);
    } finally {
      setLoadingGuest(false);
    }
    setBoundingBoxes(typeof(localStorage.getItem(`checkoBox_Video_Annotation_${userName}`) as string) === 'string' ? JSON.parse(localStorage.getItem(`checkoBox_Video_Annotation_${userName}`) as string) : false );
  }, [userName]);

  const onLanguageChange = useCallback(() => {
    const language = i18n.language === 'ja' ? 'en' : 'ja';
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  }, []);

  const translateMenus = useCallback(() => {
    const menuData = [...menu.items, { 'icon': 'Metabase', 'title': 'Visualization', isExternalLink: true, 'href': SUPERSET_URL , submenu:[
      {
        'title': 'Hourly (Today)',
        'href': SUPERSET_URL_DASHBOARD_1,
        isExternalLink: true
      },
      {
        'title': 'Daily (Last 30 days)',
        'href': SUPERSET_URL_DASHBOARD_2,
        isExternalLink: true
      },
      {
        'title': 'Monthly (Last 3 months)',
        'href': SUPERSET_URL_DASHBOARD_3,
        isExternalLink: true
      }]}];
    const data = menuData.map((item: IMenuItemTop) => {
      if (item.submenu && item.submenu.length > 0) {
        const submenu = item.submenu.map((submenu: IMenuItemSubmenu) => {
          return { ...submenu, 'title': t(submenu.title) };
        });
        return { ...item, 'title': t(item.title), 'submenu': submenu };
      } else {
        return { ...item, 'title': t(item.title) };
      }
    });
    if (!TokenService.getUserType()) {
      const guestMenuData = clonedeep(data);
      guestMenuData.splice(guestMenuData.findIndex(a => a.href === '/settings'), 1);
      return { items: [...guestMenuData] };
    } else {
      return { items: [...data] };
    }
  }, [ t]);

  useEffect(() => {
    setUserType(TokenService.getUserType());
    setMenus(translateMenus());
  }, [translateMenus]);

  useEffect(() => {
    fetchGuestData();
  }, [fetchGuestData]);

  const loginGuest = useCallback((isGuestButtonClicked: boolean) => {
    const data = {
      access_token: 'tce_guest',
      refresh_token: 'tce_guest',
      user: {
        role: 'guest',
        user_id: 0,
        username: 'Guest'
      }
    };

    sessionStorage.setItem('isGuestLogout', 'false');
    TokenService.setUser(data);
    setIsAuthenticated(true);
    if (isGuestButtonClicked) {
      window.location.href = '/cameras';
    }
  }, []);

  useEffect(() => {
    if (!loadingGuest) {
      if (guestUser) {
        if (
          (sessionStorage.getItem('isGuestLogout') && sessionStorage.getItem('isGuestLogout') === 'true')
          || location.pathname === '/login'
        ) {
          setIsAuthenticated(false);
          TokenService.removeUser();
          sessionStorage.removeItem('isGuestLogout');
          return;
        }
        if (!localStorage.getItem('user_login_info') && location.pathname !== '/login'){
          loginGuest(false);
        } else {
          setIsAuthenticated(!(TokenService?.getUser()) ? false : true);
        }
      } else {
        setIsAuthenticated(() => {
          if (!(TokenService?.getUser())) {
            if (location.pathname === '/') {
              history.replace('/login');
            }
            return false;
          }
          return true;
        });
      }
    }
    if(localStorage.getItem(`checkoBox_Video_Annotation_${userName}`) === null){
      localStorage.setItem(`checkoBox_Video_Annotation_${userName}`, 'false');
    }
  }, [loadingGuest, guestUser, userName, location, history, loginGuest]);

  const getDiskFullDetails = useCallback(async () => {
    try {
      const { data: { data } } = await getHealthStatusDetails();
      if(data.services.disk.status === 'failed') {
        setIsDiskFull(true);
      } else {
        setIsDiskFull(false);
      }
    } catch (err) {
      setIsDiskFull(false);
    }
  }, []);

  const GetSafieConfig=useCallback(async ()=>{
    try {
      const { data: { data } } = await getSafieConfigDetails();

      if(data.action !== 'DeleteStack' && data.services && data.services.safie_settings1 && data.services.safie_settings1.access_token_status && data.services.safie_settings1.access_token_status === 'NG'){
        setSafieStatusBad(true);
      }else{
        setSafieStatusBad(false);
      }
    } catch (error) {
      setSafieStatusBad(false);
    }
  },[]);

  useEffect(() => {
    if(isAuthenticated){
      getDiskFullDetails();
      GetSafieConfig();
    }
  }, [getDiskFullDetails,GetSafieConfig, isAuthenticated]);
  
  const getNotificationCount = useCallback((length: number, data: INotificationItem[])=> {
    setCount(length);
    setAlertActiveCOunt(length);
    setActiveAlertTimeDrawer(new Date().getMilliseconds());
    setAlertData(data);
  },[]);

  useEffect(() => {
    if (localStorage.getItem(`${window.location.hostname}_isMenuOpen`) === null) {
      localStorage.setItem(`${window.location.hostname}_isMenuOpen`, 'true');
    }
  }, [isAuthenticated, loadingGuest, location, history, guestUser]);

  const fetchAllUserList = useCallback(async () => {
    try {
      const { data: { status, data } } = await getAllUsers();
      if (status === 200) {
        data.map((item: {enable: boolean; username: string;}) => {
          if(item.username === TokenService?.getUser()){
            if(!item.enable){
              TokenService.removeUser();
              window.location.href = '/login';
            }
          }
        });
      } else if (status === 403) {
        notificationRef.current({ type: 'error', message: tRef.current('Authorization required to access') });
        if (await AuthService.logoutUser() !== 200) {
          notificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') });
        }
      }
      else {
        notificationRef.current({ type: 'error', message: tRef.current('Failed to load user data') });
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if(isAuthenticated && (TokenService.getUserRole() === 'admin' || TokenService.getUserRole() === 'viewer')){
      fetchAllUserList();
    }
  }, [fetchAllUserList, isAuthenticated]);

  // hide keep-open in 1366 resolution
  const hideMenuBarToggle = useCallback(() => {
    const aTags = document.getElementsByTagName('a');
    let found;

    for (let i = 0; i < aTags.length; i++) {
      if (aTags[i].textContent === 'Keep Open' || aTags[i].textContent === 'Auto-Hide') {
        found = aTags[i];
        break;
      }
    }

    if (found !== undefined) {
      if (window.innerWidth < 1366) {
        found.style.visibility = 'hidden';
      } else {
        found.style.visibility = 'visible';
      }
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      hideMenuBarToggle();
    }, 1000);

    window.addEventListener('resize', hideMenuBarToggle);

    return () => {
      window.removeEventListener('resize', hideMenuBarToggle);
      clearTimeout(timer);
    };
  }, [hideMenuBarToggle]);

  const getApp = () => {
    return (
      <>
        <MainMenuContainer
          content={menus}
          logoMark={logoMarkSvg}
          logoText={LogoTextAppNameEn}
          keepOpenText='Keep Open'
          autoHideText='Auto-Hide'
          defaultMenuOpen={false}
          canAlwaysPin
        />
        <MainContainer>
          {(isDiskFull || isSafieStatusBad) && <DiskFullNotification type='error' message={(<><MessageDiv display={isDiskFull}>{t('Disk capacity is almost full. Please perform clean-up.')}</MessageDiv><MessageDiv marginTop={isDiskFull?'3px':''} display={isSafieStatusBad}>{t('Safie connector status is bad.')}</MessageDiv></>)} />}
          <TopBar
            accountOptionText={t('Account Options')}
            loggedInUser={TokenService.getUser()}
            logoutText={t('Logout')}
            currentUserText={t('CURRENT USER')}
            onLanguageToggle={onLanguageChange}
            hasLanguage
            hasLogout={false}
            userDrawerBespoke={<PasswordModal {...{boundingBoxes, setBoundingBoxes}} />}
            userDrawerFooter={versionInfo}
            hasUserDrawerMeta
            userDrawerMeta={deviceInfo}
            hasUserDrawerFooter
            copySuccessMessage={t('Copied!')}
            // selectedLanguageText={language}
            customDrawer={{
              customComponent: <NotificationsHistory activeAlertTimePages={activeAlertTimePages} unreadNotificationsText={t(' Active Alerts')} noNotificationsText={t('No new notifications')} getNotificationCount={getNotificationCount} />,
              icon: 'Notifications',
              status: 'highlight',
              width: '300px;',
              counter: count
            }}
          />
          <Role />

          <SecureRoute path='/cameras/camera-details/:streamName/:tab' exact component={CameraDetails} />
          {userType &&
            <>
              <SecureRoute path='/cameras/camera-configuration/:streamName' exact component={CameraConfiguration} />
              <SecureRoute path='/add-camera' exact component={AddCamera} />
              <SecureRoute path='/edit-camera' exact component={AddCamera} />
              <SecureRoute path='/settings/create-user' exact component={CreateEditUser} />
              <SecureRoute path='/settings/edit-user' exact component={CreateEditUser} />
              <SecureRoute path='/settings/cloud-settings' exact component={CloudUploadSettings} />
              <SecureRoute path='/settings/safie-settings' exact component={SafieIntegration} />
              <SecureRoute path='/settings/user-management' exact component={UserManagement} />
              <SecureRoute path='/cameras/camera-configuration/:streamName/hit-point' exact component={HitPoint} />
              <SecureRoute path='/cameras/camera-configuration/:streamName/alert-suppression' exact component={AlertSuppression} />
              <SecureRoute path='/cameras/camera-configuration/:streamName/frequent-lane' exact component={FrequentLane} />
              <SecureRoute path='/cameras/camera-configuration/:streamName/traffic-counter' exact component={TrafficCounter} />
              <SecureRoute path='/cameras/camera-configuration/:streamName/reverse-run-detection' exact component={ReverseRunDetection} />
              <SecureRoute path='/cameras/camera-configuration/:streamName/exclusion-zones' exact component={ExclusionZones} />
              <SecureRoute path='/cameras/camera-configuration/:streamName/congestion-stop' exact component={CongestionStopDetection} />
              <SecureRoute path='/cameras/camera-configuration/:streamName/low-speed-detection' exact component={LowSpeedDetection} />
              <SecureRoute path='/cameras/camera-configuration/:streamName/advanced-low-speed-detection' exact component={AdvancedLowSpeed} />
              <SecureRoute path='/settings/alert-settings' exact component={AlertConfiguration} />
            </>}
          <SecureRoute path='/cameras' exact component={Cameras} />
          <SecureRoute path='/export' exact component={Export} />
          <StatusContext.Provider value={{ alertData, activeAlertTimeDrawer, alertActiveCount, activeAlertTimePages, setActiveAlertTimePages }}>
            <SecureRoute path='/all-alerts' exact component={AllAlerts} />
          </StatusContext.Provider>
          {
            (
              !validRoutes.includes(location.pathname)
              && !location.pathname.startsWith('/cameras/camera-details')
            ) ?
              (!loadingGuest && guestUser && TokenService.getUserRole() === 'guest') ?
                <SecureRoute exact path={['*']}>
                  <Redirect to='/cameras' />
                </SecureRoute>
                :
                <SecureRoute exact path={['/']}>
                  <Redirect to='/cameras' />
                </SecureRoute>
              : null
          }
        </MainContainer>
      </>
    );
  };

  const getLoginRoute = () => {
    return (
      <>
        <Route exact path='/login' render={() => <Login {...{guestUser, loginGuest, versionInfo}} />} />
      </>
    );
  };

  return (
    <Layout>
      {!loadingGuest ?
        !isAuthenticated ? getLoginRoute() : getApp()
        :
        <SpinnerBox>
          <Spinner size='large' styling='primary' />
          <Label htmlFor='loader' labelText={t('Loading') + '...'} />
        </SpinnerBox>}
    </Layout>
  );
};

export default App;
