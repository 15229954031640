import React, { useState, useEffect, useCallback, FC, useRef, ChangeEvent } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {Icon, PageHeader, RadioButton, TypeTable, useNotification, AlertBar, Button, ButtonWithIcon, useModal, Label, Content, Checkbox, TextField } from 'scorer-ui-kit';
import { IRowData, ITableColumnConfig, ITypeTableData } from 'scorer-ui-kit/dist/Tables';
import format from 'date-fns/format';
import { dateFormat } from '../utils/index';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';
import { IExportRequestPayload } from '../interface';
import DateInput from '../components/DateInput';
import { DATE_FORMAT, DATE_FORMAT_WITH_SEC } from '../constants';
import { useOnRefreshAlert } from 'hooks/useOnRefreshAlert';
import { getAllCamera, getExportTable, getLastExport, getSingleCameraDetails} from 'services/apiConfig';
import { downloadZipFile, getTimeSinceFromSeconds } from 'utils';
import { SpinnerContainer } from 'style';
import i18n from 'i18n';

const MainContainer = styled(Content)`
  width: 100%;
  padding: 45px 90px;
  @media screen and (min-width: 1440px) {
    padding: 48px 0px 62px 169px;
  }
`;

const Container = styled.div`
  max-width: 940px !important;
`;

const TextFieldRequired = styled.div`
  span::after{
    font-weight: bold;
    content: " *";
    color: rgb(238, 75, 43);
  }
`;

const ExportingContainer=styled.div`
  width:fit-content;
  height:fit-content;
`;

const EmptyDiv = styled.div<{divHeight: number}>`
  height: ${({divHeight}) => divHeight}px;
`;

const HeaderContainer = styled.div<{lang?: string}>`
  margin-bottom: ${({ lang }) => lang === 'ja' ? '70px' : '47px'};
  > div:first-child > div > h1 {
    max-width: 650px;
    overflow-wrap: break-word;
    white-space: break-spaces;
    min-width: 200px !important;
  }
  & > div > div > div:nth-child(3){
    bottom: 4px;
  }
  & > div > p {
    font-weight: normal;
    font-style: normal;
  }
  position: relative;
  max-width: 630px !important;
`;

const InnerContainer = styled.div`
  margin-left: 1px;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 9px;
  align-items: center;
`;

const SubTitle = styled.label`
  font-size: 16px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  color: #5a6269;
  margin: 0;
  padding: 0;
`;

const LabelText = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: rgba(120, 138, 144, 0.72);
  margin: 0 25px 0 0;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  padding: 0;
  line-height: 1.4;
`;

const FlexColumn = styled.div<{marginTop?:string}>`
  display: flex;
  flex-direction: row;
  margin: 9px 0 41px 2px;
  margin-top: ${({marginTop})=> marginTop};
`;

const LeftFlexColumn = styled(FlexColumn)`
  justify-Content: space-between;
  width: 500px;
  padding-top: 2px;
`;

const RadioButtonContainer = styled.div`
  margin: 0 10px 0 0;
`;

const RadioButtonContainerRight = styled.div`
  margin: 0 10px 0 27px;
`;

const ProgressingContainer = styled.div`
  margin-top: 84px;
  padding-right: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SpinnerBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: 12px solid #fff;
  border-right: 12px solid #fff;
  border-bottom: 12px solid #fff;
  border-left: 12px solid #a6e5fa;
  background: transparent;
  width: 64px;
  height: 64px;
  border-radius: 50%;
`;

const ProcessingBox = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  color: #5a6269;
  margin-top: 22px;
`;

const SubHeadingTxt = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 0 17px 0;
`;

const ProcessingBoxSubHeading = styled.p<{marginTop?:string}>`
  font-size: 14px;
  max-width: 381px;
  font-style: italic;
  line-height: 1.79;
  text-align: center;
  color: #8b9196;
  margin-top: ${({marginTop})=> marginTop?marginTop:'15px'};
  margin-bottom: 0px;
`;

const TickIconBox = styled.div`
  width: 64px;
  height: 64px;
  padding-top: 3px;
  border-radius: 50%;
  background-color: #87d58f;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SuccessErrorContainer = styled.div`
  margin-top: 84px;
  padding-right: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const TickIconBoxDanger = styled.div`
  width: 64px;
  height: 64px;
  border-radius:50%;
  background-color: #de6b6b;
  display:flex;
  justify-content:center;
  align-items: center;
`;

const ButtonDivContainer = styled.div`
`;

const TableContainer = styled.div`
  width: 500px;
  margin-bottom: 42px;
  min-height: 150px;
  opacity: 1;
  background: linear-gradient(to bottom, transparent);
  ::-webkit-scrollbar {
    display: none;
  }
  div {
    z-index: 0 !important;
  };
`;

const Column = styled.div`
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  height: 30px;
`;

const StreamNameColumn = styled(Column)`
  width: 170px;
`;

const TimeSinceColumn = styled(Column)`
  width: 150px;
`;

const ButtonContainer = styled.div`
  margin-top: 30px;
  margin-left: 2px;
  display: flex;
  gap: 10px;
`;

const AlertBarContainer = styled.div`
  width: 516px;
  position: absolute;
  margin: -46px 10px 0px 104px;
`;

const DividerTop = styled.div`
  margin-bottom: 47px;
  width: 60%;
  height: 1px;
  background-color: #eee;
`;

const DividerBottom = styled.div`
  margin-top: 43px;
  margin-bottom: 7px;
  width: 60%;
  height: 1px;
  background-color: #eee;
`;

const OtherOptionBox = styled.p`
  font-size: 14px;
  font-style: italic;
  line-height: 1.79;
  text-align: center;
  color: #8b9196;
  margin-top: 11px;
`;

const NewExportBox = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 278px;
  margin-top: -25px;
`;

const NewExportText = styled.p`
  font-size: 14px;
  line-height: 1.79;
  color: #4699d4;
  cursor: pointer;
`;

const DotContainer = styled.div`
  width: 25px;
  text-align: center;
  font-weight: bold;
  padding-top: 10px;
`;

const LastExportBox = styled.div``;

const LastExportText = styled(Label)`
  font-size: 20px;
`;

const DateShowBox = styled.div<{ marginTop?: string,marginRight?:string }>`
  display: flex;
  flex-direction: column;
  margin-right: ${({marginRight}) => marginRight};
  margin-top: ${({ marginTop }) => marginTop};
`;

const DateTitle = styled.div`
  font-size: 12px;
  margin: 0;
`;

const DateShowResult = styled(Label)`
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 16px;
  margin-bottom: 0;
`;
  
const DateShowResultWithTooltip = styled(DateShowResult)`
  > span {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const DateBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 2px;
`;

const LastButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  & > button:first-child{
    margin-right: 10px;
    background: #e4edf4;
  }
`;

const LastBox = styled.div`
  margin-top: 8px;
  position: absolute;
  right: 10px;
  top: 14px;
  button {
    background: #e4edf4;
  }
`;

const ButtonFormat = styled(Button)`
  &:focus {
    outline: 2px solid #838383;
  }
`;

const ButtonWithIconFormat = styled(ButtonWithIcon)`
  &:focus {
    outline: 2px solid #838383;
  }
`;

const BackLink = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.ui}; 
  font-size: 12px;
  font-weight: 500;
  color: #7c8793;
  margin-bottom: 4px;
  line-height: 12px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  max-width: 485px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const HeaderButton = styled.div`
  & > button:first-child {
    background: #e4edf4;
  }
`;

const BackLinkNonHover = styled.label`
  font-family: ${({ theme }) => theme.fontFamily.ui}; 
  font-size: 12px;
  font-weight: 500;
  color: #7c8793;
  margin-bottom: 4px;
  line-height: 12px;
  text-decoration: none;
`;

const Stream = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 130px;
`;

const CheckboxContainer = styled.div<{marginBottom?:string}>`
  display: flex;
  gap: 0 10px;
  margin: -10px 0 30px 3px;
  margin-bottom: ${({marginBottom})=> marginBottom};
`;

const IncludeDiv = styled.div`
  cursor: pointer;
  margin-top:0px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(120,138,144,0.72);
`;

const PageHeaderFormatter = styled.div`
  svg {
    margin-top: -7px;
  }
  h1 {
    margin-top: -6px;
  }
`;

const IntroductionText = styled.span`
  width: 610px;
  height: 40px;
  margin-top: -6px;
  margin-bottom: 32px;
  color: #7b8288;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  letter-spacing: normal;
`;

const LabelFormatter = styled(Label)`
  font-size: 22px;
`;

const SecondaryAnalysisContainer=styled.div`
  width:100%;
  height:auto;
  padding:12px 6px 0px 6px;
`;

const SecondaryAnalysisLabel=styled(Label)`
  font-size:14px;
  margin-bottom:14px;
`;

const SecondaryAnalysisPayloadBlock=styled.div`
  width:100%;
  heght:auto;
  padding:0 8px;
  background:#f4f4f4;
  border:1px solid #ccc;
`;

const SecondaryAnalysisPayloadLabel=styled.pre`
  font-size:14px;
`;

const SecondaryAnalysisHeaderURL=styled(Label)`
  font-size: 14px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  color: #5a6269;
  margin:24px 0px 8px 0px;
`;

const SecondaryAnalysisHttpURLInputFieldWrapper=styled.div`
  width:100%;
  margin:8px 0;
`;

const PaddingLeft=styled.div<{margin?:string}>`
  padding-left: ${({margin})=> margin};
`;

interface IInterval {
  start: Date,
  end: Date
}

interface IStreamStatus {
  status_code: string;
  status_category: string;
}

interface IStream {
  display_name: string;
  stream_name: string;
  deleted:boolean;
  count:number;
  role:string;
  camera_name:string;
  camera_enabled:string;
  status: IStreamStatus;
  latest_activity_time?: number;
}

interface ISelectStreamMap {
  [key: string]: boolean;
}

interface IQueryParam {
  streamName?: string;
  startDate?: string;
  endDate?: string;
  cameraName?: string;
}

interface ILastExport {
  path?: string;
  created_date?: string;
  start_date?: string;
  end_date?: string;
  sources?: string;
}

const initialRows: ITypeTableData = [
  {
    columns: []
  }
];

const Export: FC = () => {
  const [cameraDisplayNameList,setCameraDisplayNameList]=useState<string[]>([]);
  const [rowData, setRowData] = useState<ITypeTableData>([]);
  const [exporting, setExporting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [isExportError, setIsExportError] = useState(false);
  const [isAllCameraSelected, setIsAllCameraSelected] = useState(true);
  const [cameraList, setCameraList] = useState<IStream[]>([]);
  const startDate = dateFormat(new Date());
  startDate.setDate(startDate.getDate() - 1);
  const [defaultDate,] = useState<IInterval>({ start: startDate, end: dateFormat(new Date()) });
  const [initialStartDate, setInitialStartDate] = useState<Date>(defaultDate.start);
  const [initialEndDate, setInitialEndDate] = useState<Date>(defaultDate.end);
  const [currentChecked, setCurrentCheck] = useState<string | number>('ALL');
  const [streamList, setStreamList] = useState<string[]>([]);
  const [cameraName, setCameraName] = useState<string>('');
  const [selectedStreams, setSelectedStreams] = useState<ISelectStreamMap>({});
  const [searchText] = useState('');
  const [alert, setAlert] = useState<IAlert | null>(null);
  const { t } = useTranslation(['CommonDict']);
  const tRef = useRef(t);
  const { sendNotification } = useNotification();
  const sendNotificationRef = useRef(sendNotification);
  const { setContinueShow } = useOnRefreshAlert();
  const params = useLocation().search;
  const [historyParams] = useState<string>(params);
  const { createModal, setModalOpen } = useModal();
  const [camNameList, setCamNameList] = useState<string[]>([]);
  const [lastExportData, setLastExportData] = useState<ILastExport>({});
  const [zipFile, setZipFile] = useState<string>();
  const [allowExport, setAllowExport] = useState<boolean>(true);
  const [periodicSnapshot, setPeriodicSnapshot] = useState(false);
  const [secondaryAnalysis, setSecondaryAnalysis] = useState(false);
  const [cameraDisconnected, setCameraDisconnected] = useState<number>();
  const [streamName, setStreamName] = useState<string>();
  const [httpUrl,setHttpUrl]=useState('');
  const history = useHistory();
  const { goBack } = useHistory<string>();

  const getSecondaryAnalysisPayload=useCallback(()=>{
    const modifiedCameras = cameraDisplayNameList.map((camera_name) => camera_name.length > 16 ? `'${camera_name.substring(0, 16)}${'...'}'` : `'${camera_name}'`);
    const jsonObject = {
      cameras: `[${modifiedCameras.join(', ')}]`,
      time_range: {
        from: Date.parse(format(initialStartDate, DATE_FORMAT_WITH_SEC)) / 1000,
        to: Date.parse(format(initialEndDate, DATE_FORMAT_WITH_SEC)) / 1000,
      },
    };
    let jsonString = JSON.stringify(jsonObject, null, 2).replace(/"/g, '');
    jsonString=jsonString.replace(/cameras/gi, '"cameras"');
    jsonString=jsonString.replace(/time_range/gi, '"time_range"');
    jsonString=jsonString.replace(/from/gi, '"from"');
    jsonString=jsonString.replace(/to/gi, '"to"');
    jsonString=jsonString.replace(/'/gi, '"');
    return <SecondaryAnalysisPayloadLabel>{jsonString}</SecondaryAnalysisPayloadLabel>;
  },[cameraDisplayNameList,initialStartDate,initialEndDate]);

  const handleChangeHttpUrl=useCallback(({target:{value}}:ChangeEvent<HTMLInputElement>)=>{
    setHttpUrl(value);
  },[]);

  const goToPreviousPage = () => {
    goBack();
  };

  const getDetails = useCallback(async (streamdata) => {
    try {
      const res = await getSingleCameraDetails(streamdata);
      if(res?.data?.data?.usb_device_status?.status_code === 30700){
        setCameraDisconnected(res.data.data.usb_device_status.status_code);
      }
    } catch (error) {
      sendNotificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') });
    }
  }, []);

  useEffect(() => {
    if(streamName){
      getDetails(streamName);
    }
    return () => {};
  }, [getDetails, streamName]);

  const getHistoryParams = useCallback(() => {
    const queryParams = new URLSearchParams(historyParams);
    const newParams: IQueryParam = {};
    newParams.streamName = queryParams.get('streamName') || '';
    newParams.cameraName = queryParams.get('cameraName') || '';
    newParams.startDate = queryParams.get('fromDate') || '';
    newParams.endDate = queryParams.get('toDate') || '';
    if (newParams.streamName !== '') {
      setStreamName(newParams.streamName);
      setSelectedStreams({ [newParams.streamName]: true });
      setIsAllCameraSelected(false);
      setCurrentCheck('CUSTOM');
    }
    if (newParams.cameraName !== '') {
      setCameraName(newParams.cameraName);
    }
    if (newParams.startDate !== '') {
      setInitialStartDate(new Date(newParams.startDate));
    }
    if (newParams.endDate !== '') {
      setInitialEndDate(new Date(newParams.endDate));
    }
  }, [historyParams]);

  useEffect(() => {
    getHistoryParams();
    return () => {};
  }, [getHistoryParams]);

  const updateStartDate = useCallback((date) => {
    if (initialEndDate.getTime() - 60000 <= date.getTime() || date > dateFormat(new Date())) {
      setAlert({ key: new Date(),message: t('Please select From time smaller than To time'),type: 'error' });
      return;
    }
    setAlert(null);
    setInitialStartDate(date);
  }, [initialEndDate, t]);

  const updateEndDate = useCallback((date) => {
    if (initialStartDate >= date) {
      setAlert({ key: new Date(),message: t('Please select To time greater than From time'),type: 'error' });
      return;
    }
    if(date > dateFormat(new Date())){
      setAlert({ key: new Date(),message: t('Future time is not allowed'),type: 'error' });
      return;
    }
    setAlert(null);
    setInitialEndDate(date);
  }, [initialStartDate, t]);

  const fetchCameraList = useCallback(async () => {
    try {
      const {data: { data }} = await getAllCamera(1);

      const listCamera: string[] = [];
      const camNames: string[] = [];
      data.filter((camera:IStream)=>camera.stream_name).forEach((camera: IStream) => {
        listCamera.push(camera.stream_name);
        camNames.push(camera.camera_name);
      });

      const all_streams_info=[...data[data.length>1?data.length-1:0].all_streams_info];
      delete data[data.length>1?data.length-1:0];
      all_streams_info.forEach((camera:IStream) => {
        if(camera?.count>0 && camera?.deleted){
          listCamera.push(camera.stream_name);
          camNames.push(camera.display_name);
          data.push(camera);
        }
      });


      setCameraList(data);
      setCamNameList(listCamera);
      if(listCamera.length === 0) {
        setAllowExport(false);
      }
    } catch (error) {
      setCameraList([]);
    }
  }, []);

  const fetchLastExport = useCallback(async () => {
    try {
      const {data: { data }} = await getLastExport();
      setLastExportData(data);
    }
    catch (error) {
      setLastExportData({});
    }
  }, []);

  useEffect(() => {
    setTableLoading(true);
    fetchCameraList();
    setTableLoading(false);
    setInterval(() => {
      fetchCameraList();
    }, 15000);
    return () => {};
  }, [fetchCameraList]);

  useEffect(() => {
    fetchLastExport();
    return () => {};
  }, [fetchLastExport]);

  const updateSelectedStream = useCallback((enabled: boolean, value: string | number | undefined) => {
    if (value) {
      setSelectedStreams((selectedStreams: ISelectStreamMap) => ({ ...selectedStreams, [value]: enabled }));
    }
  }, []);

  const filterCamera = useCallback((cameraList: IStream[]) => {
    const streamData: IStream[] = [];
    cameraList.forEach((camera: IStream) => {
      if ((camera?.display_name?.toLowerCase().includes(searchText.toLowerCase()) || searchText === '')) {
        streamData.push(camera);
      }
    });
    return streamData;
  }, [searchText]);

  const generateRowData = useCallback(() => {
    const streamList: string[] = [];
    const camDisNames: string[] = [];
    const filterList = filterCamera(cameraList);

    const data: ITypeTableData = filterList.map((camera: IStream) => {
      if (selectedStreams[camera.stream_name]) {
        streamList.push(camera.stream_name);
        camDisNames.push(camera.camera_name?camera.camera_name:camera.display_name);
      }
      if(isAllCameraSelected) camDisNames.push(camera.camera_name?camera.camera_name:camera.display_name);
      const row: IRowData = {
        _checked: selectedStreams[camera.stream_name] || false,
        id: camera.stream_name,
        columns: [
          { customComponent: <StreamNameColumn title={camera.camera_name?camera.camera_name:camera.display_name}><Stream>{camera.camera_name?camera.camera_name:camera.display_name}</Stream></StreamNameColumn> },
          { customComponent: <TimeSinceColumn>{camera.status ? t(camera.status.status_category):camera?.deleted?t('Deleted'): <PaddingLeft margin='24px'>-</PaddingLeft>}</TimeSinceColumn> },
          { customComponent: <TimeSinceColumn>{camera.latest_activity_time ? getTimeSinceFromSeconds(camera.latest_activity_time) : <PaddingLeft margin='38px'>-</PaddingLeft>}</TimeSinceColumn> },
        ]
      };
      return row;
    });
    setStreamList(streamList);
    setCameraDisplayNameList(camDisNames);
    return data?.length ? data : initialRows;
  }, [cameraList, selectedStreams, filterCamera, t,isAllCameraSelected]);

  useEffect(()=>{
    if(currentChecked === 'CUSTOM' && Object.keys(selectedStreams).length === 0) {
      setAllowExport(false);
    } else if(currentChecked === 'ALL' && camNameList.length === 0) {
      setAllowExport(false);
    } else if(currentChecked === 'CUSTOM' && Object.keys(selectedStreams).length > 0 ) {
      const arr: string[] = [];
      Object.keys(selectedStreams).forEach(i => {
        arr.push(selectedStreams[i].toString());
      });
      if(arr.includes('true')) {
        setAllowExport(true);
      } else {
        setAllowExport(false);
      }
    } else if(currentChecked === 'ALL' && camNameList.length > 0) {
      setAllowExport(true);
    } 
    return () => {};
  },[selectedStreams, currentChecked, camNameList]);

  useEffect(() => {
    setRowData(generateRowData());
    return () => {};
  }, [generateRowData]);

  useEffect(() => {
    setContinueShow(!(exporting && loading) && ( streamList.length !== 0 || currentChecked !== 'ALL' || !isAllCameraSelected || defaultDate.start !== initialStartDate || defaultDate.end !== initialEndDate));
    return () => {};
  }, [setContinueShow, exporting, loading, streamList, currentChecked, isAllCameraSelected, defaultDate, initialStartDate, initialEndDate]);

  const tableColumns: ITableColumnConfig[] = [
    {
      header: t('Camera Name'),
      sortable: false,
      cellStyle: 'firstColumn'
    },
    {
      header: t('Status'),
      sortable: false,
      cellStyle: 'normalImportance'
    },
    {
      header: t('Last Active'),
      sortable: false,
      cellStyle: 'normalImportance'
    }
  ];

  const onDownloadZip = useCallback(async() => {
    const downloadStatus = await downloadZipFile(zipFile as string);
    if(downloadStatus){
      sendNotification({ type: 'success', message: t('Downloading started successfully') });
    } else {
      sendNotification({ type: 'error', message: t('Fail to download') });
    }
  },[zipFile, t, sendNotification]);

  const onStartExport = useCallback(async () => {
    const urlRegEx = /^(http|https):\/\//;
    if (!isAllCameraSelected && streamList.length === 0) {
      sendNotification({ type: 'error', message: t('selectCameraNotification') });
      return;
    }
    if(secondaryAnalysis && !httpUrl){
      sendNotification({ type:'error', message:t('Please enter URL for secondary analysis') });
      return;
    }
    if (secondaryAnalysis && httpUrl && !urlRegEx.test(httpUrl)) {
      sendNotification({ type: 'error', message: t('Invalid URL for secondary analysis') });
      return;
    }
    setLoading(true);
    setExporting(true);
    const payload: IExportRequestPayload = {
      type: 'image',
      action:'export',
      stream_type: currentChecked,
      cameras:cameraDisplayNameList.map(d=>encodeURI(d)),
      cam_list: isAllCameraSelected ? camNameList.map(d=>encodeURI(d)) : streamList.map(d=>encodeURI(d)),
      start_date: format(initialStartDate, DATE_FORMAT_WITH_SEC),
      end_date: format(initialEndDate, DATE_FORMAT_WITH_SEC),
      time_range:{
        from: Date.parse(format(initialStartDate, DATE_FORMAT_WITH_SEC))/1000,
        to: Date.parse(format(initialEndDate, DATE_FORMAT_WITH_SEC))/1000,
      },
      include_periodic_snapshot:periodicSnapshot,
      include_secondary_analysis_data:secondaryAnalysis,
      secondary_analysis_fetch_endpoint:secondaryAnalysis?httpUrl:''
    };
    try {
      const response = await getExportTable(payload);
      if(response.status === 200) {
        setIsExportError(false);
        setLoading(false);
        setZipFile(response?.data?.data);
      }else if(response.status === 100){
        sendNotification({ type: 'error', message: t('Invalid URL for secondary analysis') });
        setLoading(false);
        setIsExportError(true);
      } else {
        setLoading(false);
        setIsExportError(true);
      }
    }
    catch {
      setLoading(false);
      setIsExportError(true);
    }
  }, [ streamList, initialStartDate,cameraDisplayNameList, initialEndDate, currentChecked, camNameList, isAllCameraSelected, sendNotification, t, periodicSnapshot,secondaryAnalysis,httpUrl]);

  const handleChange = useCallback((value: string | number) => {
    setCurrentCheck(value);
    if (value === 'ALL'){
      const cameraNames=cameraList.map(item=>item.camera_name?item.camera_name:item.display_name);
      setCameraDisplayNameList(cameraNames);
      setIsAllCameraSelected(true);
    }else{
      const cameraNames=cameraList.filter(item=>streamList.indexOf(item.stream_name)!==-1).map(item=>item.camera_name?item.camera_name:item.display_name); 
      setCameraDisplayNameList(cameraNames);
      setIsAllCameraSelected(false);
    }
  }, [cameraList,streamList]);

  const handleNewExport = useCallback(() => {
    fetchLastExport();
    setExporting(false);
    setLoading(false);
    setIsExportError(false);
  }, [fetchLastExport]);

  const returnDashboard = useCallback(() => {
    history.push('/cameras');
  },[history]);

  const onSelectAllChecked = useCallback((checked: boolean) => {
    const allStreams: ISelectStreamMap = {};
    cameraList.forEach(({ stream_name }) => {
      allStreams[stream_name] = checked;
    });
    setSelectedStreams(allStreams);
  }, [cameraList]);
  
  const onDownload = useCallback(async() => {
    downloadZipFile(lastExportData.path as string);
  }, [lastExportData]);

  const lastExport = useCallback(() => (
    <LastExportBox>
      <LastExportText htmlFor='' labelText={t('Last Export')} />
      <DateBox>
        <DateShowBox>
          <DateTitle>{t('Export Created') + ':'}</DateTitle>
          <DateShowResult htmlFor='' labelText={format(new Date(lastExportData.created_date as string), DATE_FORMAT)} />
        </DateShowBox>
        <DateShowBox marginRight='28px'>
          <DateTitle>{t('Range') + ':'}</DateTitle>
          <DateShowResult htmlFor='' labelText={format(new Date(lastExportData.start_date as string), DATE_FORMAT) + ' - ' + format(new Date(lastExportData.end_date as string), DATE_FORMAT)} />
        </DateShowBox>
      </DateBox>
      <DateShowBox marginTop='17px'>
        <DateTitle>{t('Data Source') + ':'}</DateTitle>
        <DateShowResultWithTooltip title={(lastExportData.sources as string) === 'ALL' ? t('All Cameras') : (lastExportData.sources as string)} htmlFor='' labelText={(lastExportData.sources as string) === 'ALL' ? t('All Cameras') : (lastExportData.sources as string)} />
      </DateShowBox>
      <LastButtonBox>
        <ButtonFormat autoFocus id='CancelButton' design='secondary' onClick={() => setModalOpen(false)}>{t('Close')}</ButtonFormat>
        <ButtonWithIconFormat tabIndex={0} design='primary' icon='Download' position='left' onClick={onDownload}>{t('Download')}</ButtonWithIconFormat>
      </LastButtonBox>
    </LastExportBox>
  ), [setModalOpen, t, lastExportData, onDownload]);

  const onClickLastExport = useCallback(() => {
    createModal({
      isCloseEnable: true,
      width: '580px',
      padding: true,
      closeText: t('CLOSE'),
      customComponent: lastExport()
    });
  }, [createModal, lastExport, t]);

  const onClickCancel = () => {
    goBack();
  };

  const onCheckboxChange = useCallback((type)=>()=>{    
    if(type === 'snapshot') setPeriodicSnapshot(prev => !prev);
    if(type === 'secondaryanalysis') setSecondaryAnalysis(prev=>!prev);
  }, []);

  if(cameraDisconnected === 30700) {
    return (
      <SpinnerContainer>
        <LabelFormatter htmlFor='' labelText={t('USB device is not present, so no access to this page.')} />
      </SpinnerContainer>
    );
  }

  return (
    <MainContainer>
      <Container>
        <HeaderContainer lang={i18n.language}>
          {!window.location.href.endsWith('/export') ?
            <BackLink onClick={()=>goToPreviousPage()}>{`${cameraName}`}</BackLink>:
            <BackLinkNonHover>{t('Export Service')}</BackLinkNonHover>}
          <PageHeaderFormatter>
            <PageHeader title={t('New Export')} icon='Usage' updateDocTitle={false} />
            <IntroductionText>{!exporting ? t('Creating a new export will allow you to download an archive containing your analysis logs and media data for the query provided below.') : ''}</IntroductionText>
          </PageHeaderFormatter>
          {!exporting &&
            <LastBox>
              <Button design='secondary' disabled={Object.keys(lastExportData).length === 0} onClick={onClickLastExport} size='small'>{t('Last Export')}</Button>
            </LastBox>}
        </HeaderContainer>
        <InnerContainer>
          {
            !exporting &&
              <ExportingContainer>
                <FlexContainer>
                  <SubTitle>{t('Data Source')}</SubTitle>
                </FlexContainer>
                <FlexColumn marginTop='12px'>
                  <RadioButtonContainer onClick={() => handleChange('ALL')}>
                    <RadioButton currentChecked={currentChecked} value='ALL' />
                  </RadioButtonContainer>
                  <LabelText>{t('All Cameras')}</LabelText>
                  <RadioButtonContainerRight onClick={() => handleChange('CUSTOM')}>
                    <RadioButton currentChecked={currentChecked} value='CUSTOM' />
                  </RadioButtonContainerRight>
                  <LabelText>{t('Select Specific Cameras')}</LabelText>
                </FlexColumn>
                {
                  !isAllCameraSelected &&
                    <>
                      <LeftFlexColumn>
                        <SubTitle>{t('Select Cameras')}</SubTitle>
                      </LeftFlexColumn>
                      <TableContainer>
                        <TypeTable columnConfig={tableColumns} rows={rowData} emptyTableText={t('No Camera Found')} selectable selectCallback={updateSelectedStream} toggleAllCallback={onSelectAllChecked} isLoading={tableLoading} />
                      </TableContainer>
                    </>
                }
                <FlexContainer>
                  <SubTitle>{t('Time Range')}</SubTitle>
                </FlexContainer>
                {alert &&
                  <AlertBarContainer>
                    <AlertBar message={alert?.message} type={alert?.type} />
                  </AlertBarContainer>}
                <FlexColumn>
                  <DateInput labelText='From' date={initialStartDate} callBackFunction={updateStartDate} />
                  <DateInput labelText='To' date={initialEndDate} callBackFunction={updateEndDate} />
                </FlexColumn>
                <CheckboxContainer>
                  <Checkbox checked={periodicSnapshot} onChangeCallback={onCheckboxChange('snapshot')} />
                  <IncludeDiv onClick={onCheckboxChange('snapshot')}>{t('Include Periodic Snapshots')}</IncludeDiv>
                </CheckboxContainer>
                <CheckboxContainer marginBottom='8px'>
                  <Checkbox checked={secondaryAnalysis} onChangeCallback={onCheckboxChange('secondaryanalysis')} />
                  <IncludeDiv onClick={onCheckboxChange('secondaryanalysis')}>{t('Include Secondary Analysis Data')}</IncludeDiv>
                </CheckboxContainer>
                {
                  secondaryAnalysis && (
                    <SecondaryAnalysisContainer>
                      <SecondaryAnalysisLabel htmlFor='' labelText={t('A HTTP post is sent to this endpoint with the following payload')} />
                      <SecondaryAnalysisPayloadBlock>
                        {getSecondaryAnalysisPayload()}
                      </SecondaryAnalysisPayloadBlock>
                      <TextFieldRequired><SecondaryAnalysisHeaderURL htmlFor='' labelText='URL' /></TextFieldRequired>
                      <SecondaryAnalysisHttpURLInputFieldWrapper><TextField onChange={handleChangeHttpUrl} label='' fieldState='default' placeholder='http://your-post-http-uriport' maxLength={250} type='text' size={14} name='http_url' value={httpUrl} /></SecondaryAnalysisHttpURLInputFieldWrapper>
                    </SecondaryAnalysisContainer>
                  )
                }
                <ButtonContainer>
                  <Button disabled={!allowExport} onClick={onStartExport}>{t('Start Export')}</Button>
                  {!window.location.href.endsWith('/export') ?
                    <HeaderButton>
                      <Button design='secondary' onClick={onClickCancel}>{t('Cancel')}</Button>
                    </HeaderButton>:
                    ''}
                </ButtonContainer>
                {isAllCameraSelected && <EmptyDiv divHeight={window.innerHeight-628} />}
              </ExportingContainer>
          }
          {
            exporting && loading &&
              <ProgressingContainer>
                <DividerTop />
                <SpinnerBox>
                  <Spinner />
                </SpinnerBox>
                <ProcessingBox>
                  {t('Now Exporting …')}
                </ProcessingBox>
                <SubHeadingTxt>
                  <ProcessingBoxSubHeading marginTop='35px'>
                    {t('Please wait while we process your export. Once this has completed you will be able to download your exported data.')}
                  </ProcessingBoxSubHeading>
                </SubHeadingTxt>
                <DividerBottom />
              </ProgressingContainer>
          }
          {
            exporting && !loading &&
              <SuccessErrorContainer>
                {
                  !isExportError &&
                    <>
                      <DividerTop />
                      <TickIconBox>
                        <Icon weight='regular' icon='Success' color='inverse' size={36} />
                      </TickIconBox>
                      <ProcessingBox>
                        {t('Export Completed')}
                      </ProcessingBox>
                      <SubHeadingTxt>
                        <ProcessingBoxSubHeading>
                          {t('You can download this export data using the Download Export button below.')}
                        </ProcessingBoxSubHeading>
                      </SubHeadingTxt>
                      <ButtonDivContainer>
                        <ButtonWithIcon design='primary' icon='Download' position='left' size='small' onClick={onDownloadZip}>{t('Download Export')}</ButtonWithIcon>
                      </ButtonDivContainer>
                      <DividerBottom />
                      <OtherOptionBox>
                        {t('Other Options')}{':'}
                      </OtherOptionBox>
                      <NewExportBox>
                        <NewExportText onClick={handleNewExport}>
                          {t('Start New Export')}
                        </NewExportText>
                        <DotContainer>.</DotContainer>
                        <NewExportText onClick={returnDashboard}>
                          {t('Return To Cameras')}
                        </NewExportText>
                      </NewExportBox>
                    </>
                }
                {
                  isExportError &&
                    <>
                      <DividerTop />
                      <TickIconBoxDanger>
                        <Icon weight='regular' icon='Warning' color='inverse' size={36} />
                      </TickIconBoxDanger>
                      <ProcessingBox>
                        {t('Error Occured')}
                      </ProcessingBox>
                      <SubHeadingTxt>
                        <ProcessingBoxSubHeading>
                          {t('An error occurred during export')}
                        </ProcessingBoxSubHeading>
                      </SubHeadingTxt>
                      <DividerBottom />
                      <OtherOptionBox>
                        {t('Other Options')}{':'}
                      </OtherOptionBox>
                      <NewExportBox>
                        <NewExportText onClick={handleNewExport}>
                          {t('Start New Export')}
                        </NewExportText>
                        <DotContainer>.</DotContainer>
                        <NewExportText onClick={returnDashboard}>
                          {t('Return To Cameras')}
                        </NewExportText>
                      </NewExportBox>
                    </>
                }
              </SuccessErrorContainer>
          }
        </InnerContainer>
      </Container>
    </MainContainer>
  );
};

export default Export;